.position-relative {
  position: relative;
}

.mt-2 {
  margin-top: 20px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-5 {
  margin-top: 50px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-5 {
  margin-bottom: 50px;
}

.mr-2 {
  margin-right: 20px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 30px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-1 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 30px;
}

.p-1 {
  padding: 10px;
}

.p-24 {
  padding: 24px;
}

.pt-1 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.ph-2 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph-3 {
  padding-left: 30px;
  padding-right: 30px;
}

.gap-5px {
  gap: 5px;
}

.gap-1 {
  gap: 10px;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 30px;
}

.text_primary {
  color: #1f85cf !important;
}

.bg_primary01 {
  background-color: #04192e !important;
}

.text_light_navy_light {
  color: #b9c9da !important;
}

.text_white {
  color: #fff !important;
}

.text_darkGray {
  color: #4c545b !important;
}

.text_black {
  color: #000000 !important;
}

.text_blue_dark {
  color: #112538 !important;
}

.text_light_navy {
  color: #5d83ab !important;
}

.text_light_grey {
  color: #858e99 !important;
}

.width70 {
  width: 70%;
}

.width60 {
  width: 60%;
}

.addIcon {
  margin-right: 5px;
  margin-top: -0px !important;
}

.text_light_navy_1 {
  color: #798b99 !important;
}

.text_grey {
  color: #878787 !important;
}

.text_blue_normal {
  color: #1689ca !important;
}

.downIcon {
  margin-left: 10px;
  margin-right: -15px;
}

.text_red {
  color: #f00000 !important;
}

.text_tag {
  color: #feab3d !important;
}

.text_bold {
  font-weight: bold !important;
}

.text_500 {
  font-weight: 500 !important;
}

.text_400 {
  font-weight: 400 !important;
}

.font-8 {
  font-size: 9px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-28 {
  font-size: 28px !important;
}

.radius-1 {
  border-radius: 5px;
}

.radius-2 {
  border-radius: 10px !important;
}

.c-pointer {
  cursor: pointer !important;
}

.text-left {
  text-align: left !important;
}

.fullWidth {
  width: 100% !important;
}

.width110 {
  margin-left: -5%;
  width: 110% !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.row {
  display: flex;
  align-items: center;
}

.row-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.b-1 {
  border-radius: 5px;
}

.b-2 {
  border-radius: 10px;
}

.bg_light_blue {
  background-color: #e2f1ff;
}

.bg_white {
  background-color: #fff !important;
}

.labelOption {
  width: 100px;
  background-color: #1271a6 !important;
  color: #fff !important;
}

.dropdown-border-bottom {
  border-bottom: 1px solid #fff;
}

.flex-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.goalDropdown {
  margin-bottom: -5px !important;
  align-items: center;
  display: flex;
  cursor: pointer;
  /* text-transform: uppercase; */
  color: #a3a3a3;
  font-size: 14px;
  font-weight: 500;
}

.goalDropdown h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  font-size: 14px;
  margin: 0px !important;
}

.ownerTag > .rc-virtual-list > div > div > div > div > div {
  color: #0787f8;
  flex: none;
  width: max-content !important;
  background-color: #eaf5fd;
  border-radius: 20px;
  padding: 2px 10px;
}

.weightlist > .rc-virtual-list > div > div > div > div {
  padding: 0px 10px !important;
  min-height: 20px !important;
}

.weightBG {
  width: 100% !important;
  height: 100% !important;
  background-color: #ffb2b2 !important;
}

.addattachment {
  margin-top: 130px !important;
}

.addattachmentPlus {
  position: absolute;
}

/* .custom-input {
  font-family: "Inter";
  width: 48px;
  font-size: 14px;
  line-height: 20px;
  color: #112538;
  margin-right: 10px;
} */

.upercase {
  text-transform: uppercase !important;
}

.greyBox,
.greyBox_dashbaord {
  background: #b9c9da;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  border-radius: 25px;
  width: 25px;
  margin-right: 10px;
  height: 25px;
}

.greyBox_dashbaord {
  background: #ecf3f6;
  color: #1271a6;
  border: 1.5px solid #fff;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.25);
  margin: 0;
}

.removeOption {
  width: 150px;
  /* background-color: #04192e; */
}

.goalOption {
  width: 134px;
  max-height: calc(100vh);
  overflow-y: auto;
  background-color: #1271a6;
  margin: 0%;
  z-index: 999999999999 !important;
}

.goalPopupOption > ul {
  background-color: #1271a6 !important;
}

.goalWeightOption > .ant-dropdown-menu-submenu-title {
  color: #fff !important;
  display: flex;
  align-items: center;
}

.goalWeightOption > div > span > span {
  color: #fff !important;
}

.goalWeightOption > div:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.goalPopupOption > ul > li:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.goalOption > li:hover {
  background-color: rgba(81, 108, 133, 0.1);
}

.removeOption > li:hover {
  background-color: rgba(81, 108, 133, 0.1);
}

.peoplePop {
  width: 400px !important;
  top: 150px !important;
}

.addedButton {
  border-color: #1f85cf !important;
  display: flex;
  align-items: center;
  color: #1f85cf;
  font-size: 12px;
  height: 25px;
  font-weight: bold;
  padding-top: 3px;
}

.peopleHeight {
  max-height: 100px;
  overflow: auto;
}

.tagsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: flex-start;
}

.addButton {
  background-color: #1f85cf !important;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 25px;
  font-weight: bold;
  padding-top: 3px;
}

.cat1Cell {
  width: 50px !important;
  /* text-align: left !important; */
}

.catCell > div > div {
  width: 400px !important;
  text-align: left !important;
}

.weightCell > div > div {
  width: 150px !important;
  text-align: left !important;
}

.editable-row {
  position: relative;
  width: 80% !important;
}
.editable-row-custom {
  position: relative;
  width: 87% !important;
  z-index: 99999 !important;
}

.actionItemHeader {
  z-index: -1;
}

.sticky .editable-row {
  position: sticky !important;
  top: 0 !important;
  /* z-index: 8888 !important; */
}

.addRemoveButton {
  background-color: #1f85cf !important;
  color: #fff;
  font-size: 12px;
  height: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-top: 0px;
}

.RemoveButton {
  background-color: #ff0000 !important;
  border-color: #ff0000;
  color: #fff;
  font-size: 12px;
  height: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-top: 0px;
}

.RemoveButton:hover {
  border-color: #ff0000;
}

.removeText {
  margin-left: 5px;
  margin-top: 2px;
}

.table-cell-value {
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  color: #112538;
}

.greyBoxCategory {
  background: #b9c9da;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  padding-top: 5px;
  /* 
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  border-radius: 25px;
  width: 25px;
  margin-left: 30%;
   */
}

.BreadcrumbHome {
  background-color: #f3f9ff;
  margin-bottom: 0px;
  border-radius: 20px;
  width: fit-content;
  padding: 2px 10px !important;
  color: #1f85cf;
  font-weight: normal;
}

.BreadcrumbPlanDetails {
  background-color: #c2defb;
  margin-bottom: 0px;
  border-radius: 20px;
  width: fit-content;
  padding: 2px 10px !important;
  color: #06203a;
  font-weight: normal;
}

.progressBox {
  /* background: #F8F8F8; */
  border-radius: 5px;
  margin-top: 5px;
  /* padding: 10px; */
}

.progressBoxDetails {
  background: #f8f8f8;
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px;
}

.valueBox {
  background: #fafafa;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 4px;
  padding: 2px 5px;
}

.createPlanBtn {
  background-color: #4b8121;
  border-color: #4b8121;
  text-transform: uppercase;
  height: 40px;
  border-radius: 5px !important;
}

.inviteCollaboratorBtn {
  background-color: #1271a6;
  border-color: #1271a6;
  text-transform: uppercase;
  height: 40px;
  border-radius: 5px !important;
  font-weight: 500;
}

.cancelPlanBtn {
  height: 40px;
}

.PlanSelectActive {
  border-radius: 4px !important;
  border: 1px solid #e5e5e5 !important;
  background-color: #fff !important;
  color: #1b2a3d !important;
  box-shadow: none !important;
}
.unique-placeholder .ant-select-selection-placeholder {
  color: #4c545b !important;
}

.PlanSelectActive > .ant-select-arrow {
  color: #8e97a3 !important;
}

.PlanSelectActive > div {
  border-color: #d9d9d9 !important;
  border-radius: 3px !important;
}

.weightlist {
  /* height: 100px; */
}

.PlanSelectActive > div > .ant-select-selection-placeholder {
  color: #8e97a3 !important;
  font-weight: 400 !important;
}

/* .PlanSelect {
  border-radius: 0px !important;
  border: 1px solid #e3e5e9 !important;
  background-color: #fff !important;
  color: #1b2a3d !important;
}

.PlanSelect > .ant-select-arrow {
  color: #8e97a3 !important;
}

.PlanSelect > div {
  border-color: #d9d9d9 !important;
  border-radius: 3px !important;
}

.PlanSelect > div > .ant-select-selection-placeholder {
  color: #8e97a3 !important;
  font-weight: 400 !important;
}

.PlanSelect > div > .ant-select-selection-item {
  color: #0787f8;
  flex: none;
  height: 30px;
  line-height: 25px !important;
  width: max-content !important;
  border-radius: 20px;
  margin-top: 5px;
  padding: 2px 10px !important;
} */

.PlanSelectActive > div > .ant-select-selection-item {
  color: #0787f8;
  flex: none;
  height: 30px;
  line-height: 25px !important;
  /* width: max-content !important; */
  background-color: #eaf5fd;
  border-radius: 20px;
  margin-top: 5px;
  padding: 2px 10px !important;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
  -webkit-line-clamp: 1;
  width: calc(100% - 20px);
  max-width: max-content;
}

.addNote {
  color: #418db8;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: -10px;
  font-weight: 700;
  margin-bottom: 10px;
}

.actionName {
  width: 87%;
  font-weight: 400 !important;
  color: #112538;
}

.notesText {
  color: #4c545b;
}

.catName {
  width: 65%;
  min-height: 40px;
  margin-left: 20px;
}

.c-word-break {
  word-break: break-word;
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
}

.plusIcon {
  margin-right: 5px;
}

.tags {
  height: 20px !important;
  font-size: 12px !important;
}

.areaListBox {
  width: 250px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #f5f6fa;
  color: #1b2a3d;
  padding: 0% 15px;
  margin-bottom: 10px;
}

.areaListBoxFront {
  width: 250px;
  height: 80px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  color: #1b2a3d;
  padding: 0% 15px;
  margin-bottom: 10px;
}

.areaTextBox {
  width: 40px;
  height: 20px;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  margin-left: 20px;
  background-color: #f5f6fa;
  color: #1b2a3d;
  border-radius: 20px;
}

.checkboxDivFilter {
  width: 250px;
}

/* This property will only effect in Firefox */
@-moz-document url-prefix() {
  .checkboxDivFilterText {
    margin-top: -9px;
  }
}

.transparentBG {
  background-color: transparent !important;
  border: none !important;
}

.checkboxDivFilterSub {
  width: 240px;
  height: 30px;
}

.checkboxCollapseFilter {
  width: 250px;
  height: 30px;
}

.fillButton {
  background-color: #2163a6;
  border-color: #2163a6;
}

.whiteButton {
  color: #2163a6;
  background-color: #fff;
  border-color: #fff;
}

.font_light {
  font-weight: 300 !important;
}

/* Login CSS */

.authLayout {
  width: 100%;
  height: 100%;
}

.leftPanel {
  background-color: #1f85cf;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  width: 100%;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 100%;
}

.forgotpass {
  color: #00fff0;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.otheraccount {
  margin-top: 30px;
  margin-bottom: -35px;
  text-align: center !important;
  padding: 0px 10px;
  width: fit-content;
  z-index: 1;
  background-color: #fff !important;
}

.loginButton {
  background-color: #082847;
  border-color: #082847;
  height: 40px;
  width: 100%;
  text-transform: uppercase;
  border-radius: 5px;
}

.sigupButton {
  width: 100%;
  background-color: #47b0eb;
  text-transform: uppercase;
  border-color: #47b0eb;
  height: 40px;
  border-radius: 5px;
}

.appInput {
  border-radius: 5px;
  /* font-size: 14px !important; */
}

.isNewUserModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skipSetupButton {
  height: 60px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ebac42;
  border: none;
  color: #fff;
  border-radius: 10px;
}

.setupButton {
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #112538;
  border: none;
  color: #fff;
  border-radius: 10px;
}

.inactiveTab {
  /* border: 1px solid #5D83AB; */
  color: #fff;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.isActiveTab {
  /* border: 1px solid #5D83AB; */
  /* background-color: #5D83AB; */
  color: #fff;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.createProjectButton {
  background-color: #ff9900;
  border: 1px solid #ff9900;
  border-radius: 4px;
  height: 40px;
  width: 150px;
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 20px;
}

.dropdownLabel {
  color: #5d83ab;
  text-transform: uppercase;
  margin-right: 10px;
}

.ownerEmailDiv {
  border: 1px solid #b9c9da;
  border-radius: 3px;
  max-width: fit-content;
  padding: 5px 10px;
}

.underline {
  text-decoration-line: underline;
}

.ownerModalEmail {
  width: 100%;
  padding: 10px;
  min-height: 80px;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.inviteButton {
  width: 100%;
}

.halfWidth {
  width: 48% !important;
}

.uppercase {
  text-transform: uppercase;
}

.redButton {
  background-color: #f00000;
  border-color: #f00000;
}

.outlinePrimaryButton {
  width: 100%;
  background-color: white;
  border: 1px solid #e3e5e9;
  color: #0787f8;
  height: 50px;
  border-radius: 5px;
}

.buttonHeight {
  height: 50px;
}

.VLine {
  border-right: 1px solid #fff !important;
}

.detailsCard {
  border-radius: 0%;
  min-height: 90px;
  border: 1px solid #f3fbff;
}

.detailsCard > div {
  padding: 15px !important;
  border-radius: 0%;
}

.orangeRow {
  border-left: 2px solid orange !important;
}

.hide {
  display: none;
}

.deleteDiv:hover .hide {
  display: block;
}

.addAction {
  border: none;
  padding: 0;
  background-color: transparent;
  position: absolute;
  right: 15px;
  color: #ffa61f;
  font-size: 12px;
  font-weight: 600;
}

.positionRight {
  position: absolute;
  right: 20px;
}

.positionEnd {
  position: absolute;
  right: 15px;
  top: 20px;
}

.positionCaret {
  position: absolute;
  right: 5px;
}

.deleteIcon {
  width: 15px;
  height: 15px;
  cursor: pointer !important;
}

.actionDelete {
  display: flex;
  flex-direction: column;
}

.deleteCategory {
  border: none;
  padding: 2px 0 0 8px;
  background-color: transparent;
  height: 22px;
  border-radius: 5px;
  -webkit-box-shadow: 4px 10px 42px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 10px 42px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 10px 42px -12px rgba(0, 0, 0, 0.75);

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.topBar {
  background-color: #1271a6;
  padding: 15px;
  border-radius: 5px;
}

.sortRadio > .ant-radio-checked .ant-radio-inner {
  border-color: #feab3d !important;
}

.sortRadio > .ant-radio-checked .ant-radio-inner:after {
  background-color: #feab3d;
}

.sortRadio > .ant-radio:hover .ant-radio-inner {
  border-color: #feab3d;
}

.sortRadio > .ant-radio .ant-radio-inner {
  background-color: transparent;
  border-width: 2px;
}

.AscendingBox {
  border: 1px solid #4c545b;
  border-radius: 30px;
  padding: 5px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4c545b;
  text-transform: uppercase;
  font-size: 12px;
}

.AscendingBoxForDecision {
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 5px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
}

.color-white {
  filter: brightness(0) invert(1);
}

.selectTableRow {
  margin-bottom: -35px;
}

.divCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.example-thumb:hover {
  cursor: none !important;
}

.tooltip-slider > .ant-slider-handle {
  margin-top: -10px !important;
}

.sortByDiv {
  background-color: #fff !important;
  margin: -4px 0px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.sortByDivForDecision {
  background-color: #1271a6 !important;
  margin: -4px 0px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.sortDivider {
  background-color: #fff;
  width: 120%;
  margin-left: -20px;
}

.expendIcon {
  color: #000000 !important;
  margin-top: 2px !important;
  top: 40% !important;
}

.shortButtonDiv {
  display: flex;
  width: 100%;
  margin-top: -18.5px;
  justify-content: flex-end;
}

.fileIcon {
  width: 37px !important;
  height: 37px !important;
  object-fit: contain !important;
  margin-left: 25px;
}

.shortButton {
  text-align: end;
  width: 100px;
  margin-top: 35px;
  margin-bottom: -35px;
  /* position: relative; */
  z-index: 999;
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  color: #1271a6;
}

.option_btn {
  cursor: pointer !important;
}

.decision_table_checkbox .ant-checkbox-inner {
  border: 2px solid #cdcdcd;
  padding: 8px !important;
  border-radius: 4px;
}

.option_checkbox .ant-checkbox-inner {
  background-color: #1271a6;
  border: 2px solid #cdcdcd;
  padding: 8px !important;
  border-radius: 4px;
  margin-right: 0.5rem;
}

.ant-progress-text {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 12px;
  color: #1b2a3d !important;
}

.statusFilter > span {
  top: -15px;
}

.optionDiv {
  width: 100% !important;
  height: 32px !important;
  padding: 11px 8px !important;
  font-family: "Inter", serif !important;
  font-style: normal !important;
  text-align: left !important;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: white !important;
  cursor: pointer !important;
}

.optionDiv:hover {
  background: #418db8 !important;
}

.option_dropdown > .ant-dropdown-menu {
  display: flex !important;
  flex-direction: column !important;
  width: 228px !important;
  border-radius: 6px !important;
  box-shadow: 0 0.5px 2px 1px #c7c7c7 !important;
  background: #1271a6 !important;
  /* z-index: 1000 !important; */
  justify-content: center !important;
  align-items: center !important;
  padding: 0.5rem 0rem !important;
}

.option_dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item {
  width: 100% !important;
  background-color: transparent !important;
}

.option_dropdown > .ant-dropdown {
  z-index: 10 !important;
}

.decision_header_dropdown > .ant-dropdown-menu {
  display: flex !important;
  flex-direction: column !important;
  width: 180px !important;
  border-radius: 6px !important;
  background: #1271a6 !important;
  background: #1271a6;
  box-shadow: 0px 0px 8px rgba(16, 24, 40, 0.25) !important;
  /* z-index: 1000 !important; */
  justify-content: center !important;
  align-items: center !important;
  padding: 0.5rem 0rem !important;
}

.decision_header_dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item {
  width: 100% !important;
  background-color: transparent !important;
}

.decision_header_dropdown > .ant-dropdown {
  z-index: 1 !important;
}

.cardPopover .ant-popover-inner-content {
  padding: 0 !important;
}

.listAndCardView_dropdown > .ant-dropdown-menu {
  display: flex !important;
  flex-direction: column !important;
  width: 130px !important;
  border-radius: 6px !important;
  background: #fff !important;
  box-shadow: 0px 0px 8px rgba(16, 24, 40, 0.25) !important;
  /* z-index: 1000 !important; */
  justify-content: center !important;
  align-items: center !important;
  padding: 0.5rem 0rem !important;
  margin-top: 10px !important;
}

.listAndCardView_dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item {
  width: 100% !important;
}

.listAndCardView_dropdown > .ant-dropdown {
  z-index: 1 !important;
}

.modifyViewPopover .ant-popover-inner-content {
  padding: 0 !important;
}

.optionHeaderPopover .ant-popover-inner-content {
  padding: 0 !important;
}

.decision_detail_popover .ant-popover-inner-content {
  padding: 0rem;
}

.choose_decision_select {
  width: 100% !important;
  height: 44px !important;
  background: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 6px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose_decision_select > .ant-select-arrow {
  color: #8e97a3 !important;
}

.choose_decision_select > .ant-select-selection-placeholder {
  color: #8e97a3 !important;
  font-weight: 400 !important;
}

.choose_decision_select > .ant-select-selection-item {
  color: #0787f8;
  flex: none;
  height: 30px;
  line-height: 25px !important;
  width: max-content !important;
  border-radius: 20px;
  margin-top: 5px;
  padding: 2px 10px !important;
  border: none !important;
  outline: none !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #878787 !important;
}

.choose_decision_select .ant-select-focused {
  outline: none !important;
  border: none !important;
}

.choose_decision_select > .ant-select-focused {
  outline: none !important;
  border: none !important;
}

.choose_decision_textarea.ant-input {
  width: 100% !important;
  height: 90px !important;
  background: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 6px !important;
  resize: none;
}

.choose_decision_textarea.ant-input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #878787;
}

.ant-input-affix-wrapper {
  border: none;
}

.editDecisionDropdown .ant-popover-inner-content {
  padding: 0 !important;
}
.detail_header_popup
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content {
  padding: 0 !important;
}
.popoverWrapper {
  border-radius: 2% !important;
}
.tabbs
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-active
  > .ant-tabs-tab-btn {
  color: #1271a6 !important;
  font-size: 14px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.text-oneline,
.text-twoline,
.goalWeightOption .ant-dropdown-menu-title-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
}

.text-oneline {
  -webkit-line-clamp: 1;
  max-width: 400px;
}
.text-twoline {
  -webkit-line-clamp: 2;
  max-width: 320px;
}

.empty-div {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #878787;
  text-align: center;
}

.goalWeightOption .ant-dropdown-menu-title-content {
  -webkit-line-clamp: 1;
  max-width: 100px;
}
.goalWeightOption .ant-dropdown-menu-submenu-expand-icon {
  transform: translateY(-1px);
}

.sortByGrop {
  position: relative;
}
.sortByGrop-icon {
  position: absolute;
  left: -4px;
}

.sortByGrop .ant-select-selector {
  font-size: 12px;
  font-weight: 500;
  color: #4c545b;
  box-shadow: none !important;
  padding: 0px 10px 0px 16px !important;
}

.sortByGrop .ant-select-arrow {
  color: #4c545b !important;
  right: 12px !important;
  font-size: 10px !important;
}

.requestCollapse .ant-collapse-header {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  padding: 12px 0px !important;
}

.requestCollapse .ant-collapse-expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noRequestAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  font-size: 14px;
  color: #878787;
}

.request-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.request-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.request-header-avatar {
  text-transform: uppercase;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b9c9da;
  background-color: #b9c9da;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.request-header-content h3 {
  margin: 0;
  font-size: 14px;
  color: #404040;
  font-weight: 400;
  font-family: "Inter";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
  -webkit-line-clamp: 1;
  max-width: 150px;
}

.request-header .request-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.request-actions button {
  border: none;
  outline: none;
  background: none;
  font-size: 12px;
  font-weight: 600;
  color: #4b8121;
  font-family: "Poppins";
  margin: 0;
  padding: 0;
}

.request-actions button:nth-child(1) {
  color: #f00000;
}

.request-header-detail {
  margin-top: 6px;
  margin-left: 26px;
}

.request-header-detail h3 {
  font-size: 14px;
  font-weight: 500;
  color: #737373;
  margin-bottom: 6px;
}

.request-header-detail p {
  color: #171717;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}

.criteria_popover .ant-popover-inner-content {
  padding: 0 !important;
}
