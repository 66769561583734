.insight {
    position: relative;
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  top: -20px;
}
