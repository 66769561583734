.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 24px;
  margin-bottom: 16px;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #171717;
  }
}

.table {
  width: 100%;
  padding: 0px 24px;
}

.actions {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #f5f5f5;

  .sumOfWeight {
    font-size: 16px;
    font-weight: 600;
    color: #f00000;
    margin: 0;
    padding: 0;
  }

  .actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    button {
      border: 1px solid #1271a6;
      border-radius: 2px;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
      font-family: "Inter";
      width: 90px;
      padding: 8px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

    button:nth-child(1) {
      background-color: white;
      color: #1271a6;
    }

    button:nth-child(2) {
      background-color: #1271a6;
      color: #fff;
    }
  }
}

.categoryName {
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}
