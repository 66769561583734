.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #1271a6;
  // background-image: url("./Header.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  // border-radius: 4px;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 1300px) {
    padding: 6px 10px;
  }

  * {
    padding: 0;
    margin: 0;
  }

  .leftContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-width: 45%;
    flex: 1;

    .leftTopContent {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 4px;
    }

    .leftBottomContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;
      padding-left: 1rem;

      .solve {
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        margin-top: 12px;
        color: white;
        text-transform: capitalize;

        .solveBtn {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 28px;
          height: 28px;
          padding: 0;
          text-transform: capitalize;
          color: #fff;
          background-color: transparent;
          border: none;
          opacity: 0.5;
          margin-right: 14px;

          &.active {
            opacity: 1;
            font-weight: 700;
          }
        }

        @media (max-width: 1300px) {
          font-size: 12px;
          line-height: 20px;
          margin-top: 8px;
        }
      }

      .linkWrapper {
        display: flex;
        align-items: center;

        /* @media (max-width: 1300px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        } */

        .link {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: white;
          display: inline-block;
          background-color: #15557a;
          max-width: 336px;
          width: 100%;
          padding-right: 2px;
          border-radius: 6px;

          @media (max-width: 1300px) {
            margin-right: 10px;
          }

          div {
            scroll-behavior: smooth;
            /* width */
            ::-webkit-scrollbar {
              width: 6px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
              background: #15557a;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
              background: #418db8;
              border-radius: 5px;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            .description {
              border-bottom: dashed 1px white;
              max-width: 300px;
              overflow: hidden;
              width: 140%;
            }

            .input {
              background-color: #15557a;
              color: rgb(255, 255, 255);
              border: none;
              border-radius: 4px;
              width: 100%;
              max-width: 330px;
              font-family: "Inter", serif;
              font-style: italic;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              resize: vertical;
              overflow: auto;
              padding: 5px 15px 5px 10px;
              transform: translateY(2px);
              position: relative;
            }
            .input::-webkit-resizer {
              content: "";
              position: absolute;
              bottom: 4px;
              right: 4px;
              width: 10px;
              height: 10px;
              background-color: #15557a;
            }
            .errorBorder {
              border: 1px solid red;
            }
            .defaultBorder {
              border : 1px solid transparent;
            }
          }
        }

        .btn_container {
          display: flex;

          @media (max-width: 1300px) {
            padding-top: 10px;
            margin-right: 10px;
          }

          .button {
            margin-left: 1rem;
            width: 158px;
            height: 40px;
            background: #ecf3f7;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #1271a6;
            border: none;
            outline: none;
            text-transform: uppercase;

            @media (max-width: 1300px) {
              margin-left: 2px;
              width: 132px;
              height: 36px;
              font-size: 12px;
              line-height: 14px;
            }

            &:focus {
              box-shadow: 0 0 2px 2px silver;
            }
          }

          .modifyButton {
            margin-left: 1rem;
            width: 158px;
            height: 40px;
            background: #1371a6;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            border: 1px solid white;
            text-transform: uppercase;

            @media (max-width: 1300px) {
              margin-left: 10px;
              width: 132px;
              height: 36px;
              font-size: 12px;
              line-height: 14px;
            }

            &:hover {
              background: #ebf3f7;
              color: #1371a6;
            }

            &:focus {
              box-shadow: 0 0 2px 2px silver;
            }
          }
        }
      }
    }

    .back {
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      display: flex;
      align-items: center;

      /* &:hover {
        background: #dddada73;
      } */
    }

    .titleWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      margin-right: 8px;

      .popover {
        height: 20px;
        color: white;
        margin: 8px 20px 0 0;
      }

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: white;
          margin-right: 12px;
          overflow: hidden;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 310px;

          @media (max-width: 1300px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        svg {
          @media (max-width: 1300px) {
            display: block;
            width: 20px;
            height: 20px;
          }
        }

        img {
          cursor: pointer;
        }

        .optionIcon {
          width: 20px;
        }
      }
    }

    .deadlineWrapper {
      display: flex;
      justify-content: space-between;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      max-width: 187px;
      height: 24px;
      color: white;
      border-right: 1px solid white;
      padding-right: 8px;

      @media (max-width: 1300px) {
        line-height: 20px;
        height: 20px;
      }

      span {
        margin-right: 6px;
      }

      div {
        font-weight: 600;
      }
    }

    .duration {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #feab3d;
      margin: 0 14px;
      min-width: 84px;
    }
    .printBtn {
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      p {
        margin: 0;
        padding: 0;
        // text-decoration: underline;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }

    // .button {
    //   width: 156px;
    //   height: 40px;
    //   background: #ff9900;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   border-radius: 4px;
    //   font-family: "Poppins";
    //   font-style: normal;
    //   font-weight: 600;
    //   font-size: 14px;
    //   line-height: 16px;
    //   color: white;
    //   border: none;
    //   outline: none;
    //   text-transform: uppercase;

    //   &:hover {
    //     background: rgba(#ff9900, 0.8);
    //     box-shadow: 0 0 2px 2px silver;
    //   }

    //   &:focus {
    //     box-shadow: 0 0 2px 2px silver;
    //   }
    // }
  }

  .rightContent {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 6px;
    background: #116595;
    border-radius: 8px;
    min-width: 481px;
    // height: 110px;
    height: auto;
    box-sizing: border-box;
    position: relative;
    margin-left: 8px;

    .img {
      width: 94px;
      height: 94px;
      border-radius: 4px;
      background: silver;
      border: 2px solid #0d4d72;
      margin: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      min-width: 200px;
      // height: 82px;
      // margin: 6px 0;

      div {
        display: flex;

        img {
          margin-right: 12px;
        }
      }

      .winnion {
        display: inline-flex;
        align-items: center;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 24px;
          color: #feab3d;
          text-transform: uppercase;
        }

        svg {
          margin-right: 8px;
        }
      }

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        display: -webkit-inline-box;
        vertical-align: middle;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 320px;
        margin-bottom: 6px;

        @media (max-width: 1300px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .scoreWrapper {
        display: flex;
        .score {
          display: flex;
          flex-direction: column;
          .block {
            display: flex;
            justify-content: flex-start;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #ffffff;
            gap: 4px;

            div {
              font-weight: 600;
            }
          }
        }
        .button {
          position: absolute;
          right: 10px;
          width: 156px;
          height: 40px;
          background: #ff9900;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          text-transform: uppercase;

          @media (max-width: 1300px) {
            margin-left: 10px;
            width: 132px;
            height: 36px;
            font-size: 12px;
            line-height: 14px;
          }

          &:hover {
            background: rgba(#ff9900, 0.8);
            box-shadow: 0 0 2px 2px silver;
          }

          &:focus {
            box-shadow: 0 0 2px 2px silver;
          }
        }
      }
    }
  }
  .rightContentTop {
    display: flex;
    align-items: center;
    padding: 7px 8px;
    background: #116595;
    border-radius: 8px;
    min-width: 481px;
    height: auto;
    box-sizing: border-box;
    position: relative;
    margin-top: -26px;

    @media (max-width: 1570px) {
      margin-top: 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      div {
        display: flex;

        img {
          margin-right: 12px;
        }
      }

      .winnion {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          align-items: center;
        }
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 24px;
          color: #feab3d;
          text-transform: uppercase;
        }

        svg {
          margin-right: 8px;
        }
      }

      .topOptionSection {
        margin-top: 0.5rem;
        width: 100%;
        height: 3.5rem;
        background-color: #1271a6;
        padding: 10px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .optionContainer {
            width: 165px;
            margin-right: 14px;

            &:last-child {
              margin: 0px;
            }

            img {
              border-radius: 4px;
              height: 42px;
              width: 42px;
              object-fit: cover;
            }

            .content {
              width: 114px;
              display: flex;

              h4 {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                color: #feab3d;
                margin: 0;
                padding: 0;
                margin-bottom: 3px;
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              p {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 13px;
                color: #ffffff;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

.menu {
  padding: 0;
  height: 70px;

  div {
    cursor: pointer;
    width: 100%;
    margin: 10px 0;
    padding: 0 10px;
    border-radius: 4px;

    &:hover {
      background: #d7d7d7;
    }
  }
}

.button {
  min-width: 126px;
  width: auto;
  height: 40px;
  padding: 0 10px;
  background: #ff9900;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: white;
  border: none;
  outline: none;
  text-transform: uppercase;

  @media (max-width: 1300px) {
    width: 132px;
    height: 36px;
    font-size: 12px;
    line-height: 14px;
  }

  &:not(:disabled) {
    &:hover {
      background: rgba(#ff9900, 0.8);
      box-shadow: 0 0 2px 2px silver;
    }

    &:focus {
      box-shadow: 0 0 2px 2px silver;
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.inActive {
  background-color: transparent;
  border: none;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ecf3f7;
}
.wrapperTablet {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background: #1271a6;
  border-radius: 4px;
  height: 68px;

  .leftContentTabletWrapper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    .backWrapper {
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      display: flex;
      align-items: center;

      /* &:hover {
        background: #dddada73;
      } */
    }

    .leftContentTablet {
      display: flex;
      flex-direction: column;

      .titleTablet {
        ont-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: white;
      }

      .dedlineTabletWrapper {
        display: flex;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: white;
        height: 20px;
        margin: 10px 0;
        align-items: center;

        .duration {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          color: #b1e368;
          margin-left: 5px;
        }
        .dedlineTablet {
          font-weight: bolder;
          border-right: 1px solid white;
          //height: 15px;
          padding: 0 5px 0 3px;
        }
      }
    }
  }

  .rightContentTablet {
    display: flex;
    align-items: center;
    height: 40px;

    .button {
      margin-left: 1rem;
      background: #ecf3f7;
      color: #1271a6;
    }

    .buttonTablet {
      width: 131px;
      height: 36px;
      background: #ff9900;
      display: flex;
      justify-content: center;
      border: none;
      align-items: center;
      border-radius: 4px;
      text-transform: uppercase;
      font-family: "Poppins", serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: white;
      margin: 0 12px;

      /* &:hover {
        background: rgba(#ff9900, 0.8);
        box-shadow: 0 0 2px 2px silver;
      } */

      &:focus {
        box-shadow: 0 0 2px 2px silver;
      }
    }

    .modifyButton {
      margin-left: 1rem;
      width: 158px;
      height: 40px;
      background: #1371a6;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      border: 1px solid white;
      text-transform: uppercase;
    }

    .buttonWinningTablet {
      //width: 131px;
      height: 36px;
      background: transparent;
      display: flex;
      justify-content: center;
      border: none;
      align-items: center;
      border-radius: 4px;
      text-transform: uppercase;
      font-family: "Inter", serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #feab3d;
      margin: 0 12px;
      white-space: nowrap;

      &:hover {
        //background: rgba(#FF9900, 0.8);
        box-shadow: 0 0 2px 2px silver;
      }

      &:focus {
        box-shadow: 0 0 2px 2px silver;
      }
      span {
        margin: 0 5px;
        border-bottom: 1px solid #feab3d;
      }
    }
  }
}
@media (max-width: 767px) {
  .wrapperTablet {
    flex-direction: column;
    padding-top: 10px;
    position: relative;

    //flex-wrap: wrap;
    height: 110px;
    .leftContentTabletWrapper {
      align-items: flex-start;
      height: 40px;
    }
    .rightContentTablet {
      position: absolute;
      margin-top: 55px;
      // width: 100vw;
      justify-content: space-between;
      .buttonWinningTablet {
        margin: 0 20px;
      }
    }
    .popoverTablet {
      position: absolute;
      right: 30px;
      top: -55px;
    }
  }
}
