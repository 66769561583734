.bar {
  background: #1271a6;
  border-radius: 4px;
  padding: 14px 35px 16px 20px;
  margin-bottom: 20px;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .back {
      margin-right: 12px;
    }

    span {
      font-size: 20px;
      line-height: 30px;
      color: #fff;
      margin-right: 12px;
    }
  }

  .form {
    margin-top: 12px;
  }
}

.form {
  display: flex;
}

.progress {
  width: 20%;
  max-width: 254px;

  .title {
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    margin-bottom: 0;
  }
}

.card {
  padding: 12px 14px;
  border-right: 1px solid #e3e5e9;

  &.first {
    max-width: 394px;
    min-width: 25%;
    width: 25%;
  }

  .title {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 8px;
    color: #ffffff;
  }

  .inputWrapper {
    display: flex;
    align-items: flex-end;

    .customInput {
      font-family: "Inter";
      height: 26px;
      // width: 100%;
      font-size: 14px;
      line-height: 26px;
      color: #112538;
      margin-right: 10px;
      border-radius: 4px;
      padding-left: 10px;
      border: none;
    }

    .customInput::-webkit-outer-spin-button,
    .customInput::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }

    .unit {
      font-family: "Inter";
      font-weight: 400;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
    }

    .unitText {
      font-family: "Inter";
      font-weight: 400;
      color: #fff;
      margin-top: 2px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.ManageCustomMetricsStyle {
  :global(.ant-modal-content) {
    border-radius: 4px;
    font-family: "inter"
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #737373;
    margin-bottom: 5px;
  }

  /* Styles for the modal container */
  .FooterRow {
    background-color: #f5f5f5;
    padding: 10px;
    margin: -10px;
  }

  .CancelButton {
    background-color: transparent;
    color: #1271a6;
    border: 1px solid #1271a6;
    width: 88px;
  }

  .SaveButton {
    background-color: #1271a6;
    color: white;
    width: 88px;
  }

  .HeaderRow {
    margin-bottom: 16px;
  }

  .HeaderText {
    font-size: 16px;
    color: black;
    font-weight: bold;
  }

  .CrossIcon {
    cursor: pointer;
  }

  .MetricsContainer {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ced0d4 !important;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #aeb0b5 !important;
    }

    scrollbar-width: thin;
    scrollbar-color: #ced0d4 #f1f1f1;
  }

  .MetricBlock {
    padding: 10px;
    margin-bottom: 10px;
    background-color: transparent;

    &.expanded {
      background-color: #f5f5f5;
    }
  }

  .MetricRow {
    padding: 8px;
    border-radius: 5px;
  }

  .MetricCol {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }

  .ArrowIcon {
    transition: transform 0.3s;
  }

  .MetricTitle {
    font-size: 14px;
    margin: 0;
  }

  .DeleteCol,
  .DeleteUnitCol {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
  }

  .DeleteBox {
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .MetricInputCol {
    margin-bottom: 10px;
  }

  .MetricInput {
    margin-bottom: 10px;
  }

  .UnitRow {
    margin-top: 8px;
  }

  .UnitInputCol {
    margin-bottom: 10px;
  }

  .UnitInput {
    margin-bottom: 10px;
  }

  .AddUnitCol {
    display: flex;
    justify-content: end;
  }

  .AddUnitText {
    font-size: 14px;
    color: #1271a6;
    cursor: pointer;

    span {
      font-size: 18px;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .AddMetricContainer {
    display: flex;
    justify-content: end;
  }

  .AddMetricText {
    font-size: 14px;
    color: #1271a6;
    cursor: pointer;

    span {
      font-size: 18px;
    }
  }

  .ErrorText {
    color: red;
    font-size: 12px;
    margin-top: -10px;
  }

}