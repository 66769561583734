.assistant {
  padding: 0;
  position: relative;
  height: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  scrollbar-width: none;

  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;

  .assistant_header {
    width: 100%;
    padding-bottom: 9px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      text-transform: uppercase;
      font-size: 14px;
      color: #112538;
      font-weight: 700;
      font-family: "Inter", sans-serif;
    }
  }

  .assistant_loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      margin-top: 12px;
      margin-bottom: 20px;
      color: #4c545b;
      font-weight: 700;
      font-size: 18px;
      text-transform: capitalize;
    }

    button {
      margin-top: 160px;
      background-color: #ecf3f6;
      color: #1271a6;
      border: none;
      padding: 8px 12px;
      border-radius: 3px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }
  }

  .assistant_lists {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: calc(100vh - 175px);
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ced0d4 !important;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #aeb0b5 !important;
    }

    scrollbar-width: thin;
    scrollbar-color: #ced0d4 #f1f1f1;

    .assistant_list {
      padding: 8px;
      border-radius: 3px;
      background-color: #f5f5f5;
      display: flex;
      gap: 6px;

      .assistant_list_content {
        h5 {
          font-size: 12px;
          font-weight: 400;
          color: #171717;

          span {
            font-weight: 600;
          }
        }

        .tags {
          font-size: 10px;
          color: #fff;
          font-weight: 500;
          background-color: #59a310;
          width: max-content;
          padding: 2px 6px;
          border-radius: 24px;
        }
      }
    }
  }

  .assistant_actions {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-size: 12px;
      font-weight: 500;
      font-family: "Inter", sans-serif;
      display: flex;
      align-items: center;
      border-radius: 3px;
      gap: 6px;
      padding: 8px 12px;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

    button:nth-child(1) {
      background-color: transparent;
      border: none;
      color: #1271a6;
    }

    button:nth-child(2) {
      background-color: #1271a6;
      color: #fff;
      border: none;
    }
  }

  .assistant_footer {
    bottom: 0;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
