.cardViewWrapper {
  box-shadow: 0px 4px 20px 0px rbga(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 350px;
  background-color: #fff;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  padding: 16px;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    width: 4px; // Set the width of the scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Background of the scrollbar track
    z-index: -1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced0d4 !important; // Color of the scrollbar thumb
    border-radius: 10px; // Rounded corners
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #aeb0b5 !important; // Darker color on hover
  }

  /* Firefox scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #ced0d4 #f1f1f1;

  .cardViewHeader {
    padding: 12px 0px;
    margin-bottom: 24px;

    .status {
      padding: 4px 8px;
      display: flex;
      align-items: center;
      gap: 10px;
      width: max-content;
      border-radius: 4px;

      h3 {
        margin: 0;
        padding: 0;
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }

      h5 {
        margin: 0;
        padding: 0;
        color: #737373;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .card {
      padding: 14px;
      box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
      border-left: 3px solid;
      border-radius: 8px;
      background-color: #ffffff;

      .top {
        display: flex;
        gap: 10px;
        margin-bottom: 14px;
        align-items: center;

        h4 {
          margin: 0;
          padding: 0;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          flex: 1;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          word-break: break-word;
          -webkit-line-clamp: 2;
        }
      }

      .center {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 16px;

        .weight {
          display: flex;
          align-items: center;
          gap: 6px;
          border: 1px solid #cdcdcd;
          border-radius: 4px;
          padding: 4px;

          span {
            color: #404040;
            font-size: 12px;
            font-weight: 500;
          }
        }

        .perioty {
          display: flex;
          align-items: center;
          gap: 6px;
          border-radius: 20px;
          padding: 2px 10px;
          font-size: 12px;
          font-weight: 400;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .dates {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 14px;
          flex: 1;

          h4 {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0;
            margin: 0;
            font-size: 12px;
            font-weight: 500;
            color: #737373;
            white-space: nowrap;
          }
        }

        .assigne {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 1px solid #ffffff;
          background-color: #b9c9da;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          font-weight: 600;
          overflow: hidden;
        }

        .assigne_image {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 1px solid #ffffff;
          display: flex;
          overflow: hidden;
        }
      }
    }
  }
}
