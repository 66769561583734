.heading {
  font-size: 18px;
  color: #171717;
  font-weight: 500;
  margin: 25px 0px 15px 2px;
  text-transform: capitalize;
}

.planList {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-left: 6px solid;

  &:hover {
    background-color: #f9f9f9;
  }

  .accordion {
    width: 40%;

    .accordionTitle {
      display: flex;
      // align-items: center;
      gap: 20px;

      .title {
        color: #404040;
        font-size: 14px;
        font-weight: 400;
        font-family: "Inter";
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-break: break-word;
        -webkit-line-clamp: 1;
        transition: all 0.3s ease;
        flex: 1;
      }

      .arrow {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        top: 4px;
      }
    }

    .NoteContent {
      padding-left: 38px;

      .NoteHeading {
        color: #171717;
        font-weight: 400;
        font-size: 10px;
        font-family: Inter;
        margin-top: 9px;
      }

      .NoteContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;

        opacity: 0;
        visibility: hidden;
      }

      // opacity: 0;
      // visibility: hidden;
    }
  }

  .duration {
    width: 10%;
    text-wrap: nowrap;
  }

  .status {
    width: 10%;
  }

  .deadline {
    width: 10%;
    display: flex;
    align-items: center;
    // justify-content: center;
    color: #171717;
    font-size: 14px;
    font-weight: 400;
    gap: 4px;
  }

  .assignee {
    width: 10%;

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      background-color: #b9c9da;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.listHeader {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 20px;
  margin: 0;
  border-left: 6px solid;

  li {
    color: #171717;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter";
    list-style: none;
    display: flex;
    width: 10%;
  }

  li:nth-child(1) {
    width: 40%;
  }
}
