.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  height: 38px;
  border-radius: 4px;
  resize: none;
  padding-left: 15px;
  padding-right: 10px;
  border: none;
  width: 100% !important;
}

.textarea {
  min-height: 90px;
  border-radius: 4px;
  resize: none;
  border: none;
  vertical-align: text-top !important;
  width: 100% !important;
  padding-left: 15px;
}

.inputDiv {
  width: 100%;
}

input:active {
  outline: none !important;
}
input:focus {
  outline: none !important;
}
textarea:active {
  outline: none !important;
}
textarea:focus {
  outline: none !important;
}

.input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: rgb(180, 177, 177) !important;
}
::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: rgb(180, 177, 177) !important;
}
.input:focus {
  border-color: #2d5edd;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.error {
  border-color: #f0280d !important;
  color: #f0280d;
}

.errorMessage {
  color: #f0280d;
  font-size: 10px;
  position: absolute;
  top: calc(100% + 5px);
}

.inputLabel {
  font-weight: 500;
}
