:global {
  html {
    overflow: hidden;
  }
}

.bg {
  // display: flex;
  // justify-content: center;
  // align-items: flex-start;
  // background: rgba(0, 0, 0, 0.6);
  // position: fixed;
  // z-index: 15;
  // width: 100%;
  // height: 100%;
  // top: 0;
  // left: 0;
}

.mainContainer {
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1052px;
  width: 100%;
  height: 100vh;
  max-height: 682px;
  position: relative;

  .overlay_cancel_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    z-index: 10;
    background: rgba(255, 255, 255, 0.93);
    backdrop-filter: blur(2px);
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #112538;
      margin: 0;
    }
    .btn_container {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      .close_button {
        width: 70px;
        height: 40px;
        background: #ecf3f7;
        border-radius: 6px;
        border: none;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #1271a6;
        cursor: pointer;
      }
      .cancel_button {
        width: 79px;
        height: 40px;
        background: #1271a6;
        border-radius: 6px;
        border: none;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
  .header {
    width: 100%;
    height: 55px;
    // background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
    background-color: #ffffff;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    // border-radius: 4px 4px 0 0;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 16px;
      color: #06203a;
    }

    .close {
      width: 28px;
      height: 28px;
      border: 1px solid #fff;
      background: #ecf3f7;
      border-radius: 6px;
      padding: 0;
      outline: none;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      // &:hover {
      //   background-color: rgba(#fff, 0.25);
      // }
    }
  }
  .separator_container {
    width: 100%;
    background: #ffffff;
    .separator {
      border-bottom: 1px solid #d9d9d9;
      max-width: 97%;
      margin: auto;
    }
  }

  .details_container {
    background-color: #fff;
    padding: 10px 20px 12px;
    .generateWithAI {
      width: max-content;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-top: 1rem;
      cursor: pointer;
      text-align: left;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #1271a6;
    }
    h6 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #06203a;
      margin: 0 0 0.3rem 0;
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #878787;
      display: block;
    }
  }

  .body {
    overflow-y: auto;
    flex: 1;
    background-color: #fff;
    padding: 0 20px 12px;
  }

  .checkbox {
    padding: 10px 0;
    margin-top: 10px;
    span {
      margin: 0;
    }
    :global(.ant-checkbox-inner) {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      transform: translateY(-20px);

      &::after {
        left: 30.5%;
      }
    }

    :global(.ant-checkbox-checked .ant-checkbox-inner) {
      background-color: #1689ca;
      border-color: #1689ca;
    }
  }

  .tableWrapper {
    // margin-top: 12px;
    // min-height: 100px;
  }

  .colored_table {
    .box {
      background: #e7eef1 !important;
      .input {
        background: #e7eef1;
        border: 1px solid rgba(6, 32, 58, 0.14);
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 6px;
        height: 44px;
      }
      .textarea {
        background: #e7eef1;
        border: 1px solid rgba(6, 32, 58, 0.14);
        border-radius: 4px;
        min-height: 58px;
      }
      .score_textarea {
        background: #ffffff;
      }
    }
  }

  .disabled_table {
    .box {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .table {
    width: 100%;
    display: flex;

    &.head {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    /* .column {
      display: flex;
      flex-direction: column; */
    // width: 30%;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px;
      background: #418db8;
      border-right: 1px solid #71aaca;
      border-radius: 0 4px 0px 0;
      height: 60px;
      position: sticky;
      top: -1px;
      z-index: 1;

      &.first {
        border-radius: 4px 0 0 0;
      }

      &.last {
        border-radius: 0 4px 0 0;
        padding: 20px 16.5px;
      }

      .warning {
        color: #ff5e5e;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        &.equall {
          color: #9bc961;
        }
      }

      .buttonAdd {
        background-color: transparent;
        outline: none;
        border: none;
        // padding: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #ecf3f7;
        border-radius: 6px;
        width: 115px;
        height: 32px;
        svg {
          // margin-right: 5px;
        }

        span {
          font-family: "Inter";
          color: #1271a6;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
        }
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: white;

        div {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #feab3d;
        }
      }
    }

    .criteria {
      display: flex !important;
      gap: 0.5rem;
      align-items: center;
      width: 100%;

      span {
        font-family: "Inter";
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-weight: 600;
        font-size: 14px;
        color: #525252;
      }

      .disabled_criteria_number {
        width: 26px;
        background: #8db4ca;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 5px;
      }

      .criteria_number {
        width: 26px;
        background: #418db8;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .box_popup {
      padding-right: 2px !important;
    }

    .disabled_box {
      background-color: red;
    }

    .box {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 10px 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px #fff solid;
      height: 100%;
      background: #e7eef1;

      &.first {
        border-right: 1px #fff solid;
      }

      &.inactive {
        opacity: 0.5;
        pointer-events: none;
      }

      &.actionsBox {
        padding: 8px 16.5px;
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 20px;
    border: 1px solid #e3e5e9;
    background-color: #fff;

    .btn {
      display: flex;
      align-items: center;
      outline: none;
      padding: 0;
      border: none;
      background-color: transparent;

      .next_icon {
        width: 28px;
        height: 28px;
        background: #ecf3f7;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        cursor: pointer;
        border: none;
      }

      figure {
        margin: 0 10px 0 0;
      }

      .disabled {
        cursor: not-allowed;
        background-color: rgba(175, 175, 175, 0.3) !important;
        svg {
          path {
            stroke: rgba(179, 175, 175, 0.7);
          }
        }
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #1271a6;
        display: block;
      }
    }

    .reverse_btn {
      flex-direction: row-reverse;

      figure {
        margin: 0 0 0 10px;
      }
    }

    .save_btn {
      button {
        width: 65px;
        height: 40px;
        background: #1271a6;
        border-radius: 6px;
        border: none;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }
    }

    // button {
    //   // background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
    //   border-radius: 4px;
    //   background: none;
    //   border: none;
    //   // padding: 8px 12px;
    //   font-family: "Inter";
    //   font-style: normal;
    //   font-weight: 500;
    //   font-size: 14px;
    //   line-height: 20px;
    //   color: #1271a6;

    //   &:disabled {
    //     opacity: 0.5;
    //   }
    // }
  }
}

.sliderWrapper {
  margin-right: 12px;
  .slide_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 0.4rem;
  }
  p {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #171717;
  }
}

.sliderPercentage {
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
  }
  // p {
  //   border-bottom: 1px solid black;
  // }
}

.addCriteria {
  position: absolute;
  z-index: 100;
  top: 20vh;
  left: calc(20vw - 285px);
}

.dropdown {
  /* :global(.ant-popover) {
    transform: translateX(20px) !important;
  } */

  :global(.ant-popover-inner-content) {
    padding: 0;
  }

  :global(.ant-popover-arrow) {
    display: none;
  }
}

.dropdownToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  pointer-events: visible;
  &:hover {
    cursor: pointer;
    background: #d5d1d1;
  }
}

.settings {
  background: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  button {
    font-family: "Poppins";
    padding: 13px 20px;
    color: #4c545b;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    outline: none;
    background: transparent;
    text-align: left;

    &:not(:last-of-type) {
      border-bottom: 1px solid #e6e7eb;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      background-color: #ebebeb;
    }
  }
}

/* .settings {

} */

/* .deleteCriteria {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
}
 */

.input,
.textarea {
  width: 100%;
  height: 44px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e3e5e9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  &::placeholder {
    color: #878787;
  }
}

.textarea {
  min-height: 52px;
  max-height: 154px;
}

.weightInput {
  width: 60px;
  border: 1px #00d0ff solid;
  border-radius: 4px;
}
