.dropdownTrigger {
  cursor: pointer;
  font-size: 14px;
  color: #171717;
  background-color: #ffffff;
  padding: 8px 12px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 100%;
  max-width: 200px !important;
}

.dropdownTrigger {
  :global(.ant-space-item:nth-child(1)) {
    width: 100%;
    max-width: 200px !important;
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-word;
    -webkit-line-clamp: 1;
  }
}

.customDropdownMenu {
  box-shadow: 0 0 10px #00000024;
  padding: 10px 5px 5px 5px;
  background-color: #fff;
  width: 140px;

  .inner {
    overflow: auto;
    max-height: 200px;
    background-color: orange !important;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
      z-index: -1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ced0d4 !important;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #aeb0b5 !important;
    }

    // scrollbar-width: thin;
    scrollbar-color: #ced0d4 #f1f1f1;
  }

  .lastItem {
    text-align: end;
    padding-top: 5px;
    padding-right: 10px;
    font-weight: 500;
    color: #1271a6;
    font-size: 12px;
  }

  :global(.ant-dropdown-menu-submenu-title) {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    padding: 6px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center !important;
  }

  :global(.ant-dropdown-menu-title-content) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-word;
    -webkit-line-clamp: 1;
    max-width: 110px !important;
  }

  .ant-dropdown-menu {
    box-shadow: none;
  }

  :global(.ant-dropdown-menu-item) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-word;
    -webkit-line-clamp: 1;
    max-width: 120px !important;
  }
}
