.header {
  background: #04192e;
  display: flex;
  justify-content: space-between;
  height: initial;
  padding: 0 0 0 16px;
  min-height: 76px;

  @media (max-width: 1300px) {
    padding: 2px 11px 2px 16px !important;
    height: 56px;
  }
}

.menuIcon {
  border-right: 1px solid #04192e;
  width: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  cursor: pointer;

  @media (max-width: 1300px) {
    width: 60px;
    height: 58px;
  }
}

.headerbody {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoWrapper {
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
}

.pplogo {
  display: flex;
  width: 190px;
  margin-left: 10px;
  // height: 46px;
}

.atlogo {
  width: 141px;
  margin-left: 4px;
}

.dmlogo {
  @media (max-width: 1300px) {
    width: 180px;
  }
}

.headerlogoText {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}

.headerlogoTextDes {
  color: #fff;
  margin-left: 10px;
  font-size: 14px;
  line-height: normal;
}

.headerlogoTextDes1 {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
}

.row {
  display: flex;
  align-items: center;
}

.profileView {
  display: flex;
  align-items: center;
  // margin-right: 15px;
}

.logoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  height: 50px;
}

.profileText {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 16px;
}

.profilePicture {
  margin-left: 20px;
  object-fit: cover;
  border-radius: 50%;

  @media (max-width: 1300px) {
    margin-left: 12px;
  }
}

.count {
  color: #fff;
  background-color: #f00000;
  font-size: 10px;
  margin-top: -20px;
  margin-left: -10px;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notificationIcon {
  width: 50px;
  padding: 15px;
  height: 50px;
  background-color: transparent !important;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-right: -20px;
  border-radius: 2px !important;
  background-repeat: no-repeat;
  background-position: center;
}

.notificationIcon {
  width: 80px;
  margin-right: -50px !important;
  z-index: 999 !important;
}

.notificationIcon > div {
  border: none !important;
}

.notificationIcon > span {
  display: none !important;
}

.notification {
  z-index: 99;
}

.NotificationView {
  width: 450px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 9999 !important;
  margin: -4px 0px;
  border: 3px solid #04192e;
}

.hdots_container {
  width: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .hdots {
    margin-right: 5px;
    transform: rotate(90deg);
    cursor: pointer;
  }
}

.reduceMargin {
  margin: 10px 0px !important;
}

.vdots {
  margin-left: 20px;
  cursor: pointer;
}

.options {
  color: #1b2a3d;
  font-weight: 500;
  font-size: 12px;
}

.optionRed {
  color: #f00000;
  font-weight: 500;
  font-size: 12px;
}

.menu {
  padding: 5px;
  border-radius: 5px;
}

.notificationCard {
  padding: 15px 15px;
  cursor: pointer;
  border-bottom: 1px solid #ddeeee;
}

.profilePicture {
  width: 56px;
  height: 56px;

  @media (max-width: 1300px) {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 1199px) {
  .header {
    padding: 14px 16px !important;
    height: 60px;

    .headerbody {
      padding: 0 !important;
    }

    .row {
      margin-left: 14px;
    }
  }

  .profileView {
    margin: 0;
  }

  .profilePicture {
    width: 32px;
    height: 32px;
    margin: 0 0 0 10px;

    @media (max-width: 1300px) {
      margin: 0 0 0 6px;
    }
  }

  .profileText {
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .headerlogoTextDes1 {
      font-family: "Poppins", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;

      @media (max-width: 1300px) {
        margin-bottom: 0;
      }
    }

    .headerlogoText {
      font-family: "Poppins", serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
