.planList {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }

  .title {
    color: #171717;
    font-size: 17px;
    font-weight: 500;
    font-family: "Inter";
    width: 35%;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-word;
    -webkit-line-clamp: 1;
  }

  .progressWrapper {
    width: 12%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    .text {
      color: #737373;
      font-size: 14px;
      font-weight: 400;
      font-family: "Inter";
      text-align: right;
    }
  }

  .update {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropdownMenu {
    width: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .deadline {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #171717;
    font-size: 14px;
    font-weight: 400;
    gap: 4px;
  }
}

.listHeader {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 20px;
  margin: 0;

  li {
    color: #171717;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter";
    list-style: none;
    display: flex;
  }

  li:nth-child(1) {
    width: 35%;
  }

  li:nth-child(2),
  li:nth-child(3) {
    width: 12%;
  }

  li:nth-child(4) {
    width: 10%;
    align-items: center;
    justify-content: center;
  }

  li:nth-child(5),
  li:nth-child(6) {
    width: 10%;
    align-items: center;
    justify-content: center;
  }

  li:nth-child(7) {
    width: 5%;
    align-items: center;
    justify-content: center;
  }

  li:nth-child(8) {
    width: 3%;
    align-items: center;
    justify-content: center;
  }
}

.menuItem,
.deleteItem {
  display: flex;
  align-items: center;
  gap: 10px;
  color: "#1B2A3D";
  padding: 4px 0px;
}

.menuItem {
  &:hover {
    color: #1271a6;
  }
}
.deleteItem {
  &:hover {
    color: red;
  }
}
