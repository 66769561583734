.custumModel {
  position: relative;
  .modelHeader {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h3 {
      font-size: 18px;
      font-weight: 600;
      color: #171717;
      margin: 0;
      padding: 0;
    }
  }
  .main {
    padding: 0px 24px;

    label {
      font-size: 14px;
      font-weight: 500;
      color: #737373;
      //   color: red;
      margin-bottom: 6px;
    }

    textarea {
      width: 100%;
      height: 80px;
      font-family: "Inter";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      resize: none;
      border: none;
      background-color: #fff;
      border: 1px solid #e5e5e5;
      padding: 12px;

      &::placeholder {
        color: #a3a3a3;
      }

      &:focus {
        box-shadow: 0px 0px 2px 2px #d0e3ed;
        border: 1px solid #71aaca;
      }
    }

    .container {
      width: 100%;
      margin-bottom: 16px;
    }

    .viewLess {
      width: 100%;
      padding: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      h4 {
        font-size: 14px;
        font-weight: 500;
        color: #1271a6;
        max-width: max-content;
        margin: 0;
        padding: 0;
        cursor: pointer;
      }
    }

    .boxs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 16px;

      .box {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    .metricContainer {
      background-color: #fafafa;
      padding: 12px 16px;
      border-radius: 4px;
      margin: 0px -12px;
    }
  }

  .actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px 24px;
    background-color: #f5f5f5;
    margin-top: 16px;

    button {
      border: 1px solid #1271a6;
      border-radius: 2px;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
      font-family: "Inter";
      padding: 8px 20px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

    button:nth-child(1) {
      background-color: transparent;
      color: #1271a6;
    }

    button:nth-child(2) {
      background-color: #1271a6;
      color: #fff;
    }
  }
}
