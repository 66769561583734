.planHeader {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 20px;
  border-bottom: 1px solid #c1cad1;
  background-color: #fff;

  .back {
    margin-top: -2px;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1271a6;
    font-family: "Inter";
  }
}

.projectPlanDetails {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  justify-content: space-between;
  gap: 25px;
  background-color: #fff;

  .projectPlanDetails_top_wrapper {
    width: 100%;
    display: flex;
    gap: 24px;
  }

  .projectPlanDetails_bottom_wrapper {
    width: 100%;
    position: relative;
    display: flex;
    gap: 24px;
  }

  .projectPlanDetails_form {
    width: 80%;
    min-height: 440px;
    max-height: 100%;
    padding: 24px;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
    padding-bottom: 40px;

    .projectPlanDetails_header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 30px;
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #f5f5f5;

      h2 {
        margin: 0;
        padding: 0;
        color: #262626;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .form {
      width: 100%;
      justify-content: space-between;
      display: flex;
    }

    .form_left {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-right: 24px;
      padding-right: 24px;
      border-right: 1px solid #f5f5f5;
    }

    .form_right {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .collaborators,
    .techStack,
    .documents {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      border: 1px solid #f5f5f5;
      border-radius: 6px;

      .collaborators_heading,
      .techStack_heading,
      .documents_heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .actions {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        .heading {
          margin: 0;
          padding: 0;
          color: #404040;
          font-size: 16px;
          font-weight: 500;
        }

        // svg {
        //   cursor: pointer;
        // }

        .actionButton {
          color: #1271a6;
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
          }
        }
      }

      .collaborators_list {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-wrap: wrap;
        gap: 8px;

        .collaborators_avatar {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.15),
            0px 1px 3px 0px rgba(16, 24, 40, 0.15);
          background: #b9c9da;
          color: #fff;
          font-size: 16px;
        }
      }

      .techStack_lists {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-wrap: wrap;
        gap: 8px;

        .techStack_list {
          color: #404040;
          font-size: 14px;
          font-weight: 500;
          background-color: #f5f5f5;
          border-radius: 24px;
          padding: 6px 12px;
        }
      }

      .documents_lists {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .documents_list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;

          .documents_list_left {
            display: flex;
            align-items: center;
            gap: 12px;

            .documents_icon {
              width: 40px;
              height: 40px;
              border-radius: 100%;
              background-color: #ecf3f6;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .documents_content {
              display: flex;
              flex-direction: column;
              gap: 4px;

              h3 {
                margin: 0;
                padding: 0;
                color: #171717;
                font-size: 16px;
                font-weight: 400;
              }

              span {
                color: #737373;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .duration {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        width: 120px;
      }

      h6 {
        margin: 0;
        padding: 0;
        color: #a3a3a3;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .inputGroup {
      display: flex;
      gap: 40px;

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        margin-top: 10px;
        width: 130px;
        white-space: nowrap;
      }

      .selectWrapper {
        background-color: #fff;
        border: 1px solid #e5e5e5;
      }

      .datePickerWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .inputWrapper,
      .selectWrapper {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .Ai_input {
          position: relative;
          display: flex;
          align-items: center;

          .ai_Icon {
            position: absolute;
            right: 12px;
            width: 20px;
            height: 20px;
            border-radius: 2px;
            border: 2px solid #1271a6;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s ease-in-out;

            &:hover {
              opacity: 1;
              visibility: visible;
            }
          }

          input {
            padding-right: 40px;

            &:focus + .ai_Icon {
              opacity: 1;
              visibility: visible;
            }

            &:hover + .ai_Icon {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        input {
          width: 100%;
          border: 1px solid #e5e5e5;
          background-color: #ffffff;
          border-radius: 4px;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          font-family: "Inter";
          color: #171717;
        }
      }

      .error {
        font-family: "Roboto";
        font-size: 12px;
        margin-top: 4px;
        color: #ff0000;
      }

      .goal_input_wrapper {
        position: relative;
        flex: 1;
        width: 100%;

        .goal_input {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          background-color: #ffffff;
          margin: 0;
          padding: 0;
          padding: 10px;
          font-size: 14px;
          color: #a3a3a3;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          user-select: none;
          gap: 10px;

          .goal_input_selected {
            width: 100%;

            h3 {
              margin: 0;
              padding: 0;
              color: #171717;
              font-weight: 400;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              white-space: normal;
              word-break: break-word;
              -webkit-line-clamp: 1;
            }
          }
        }
      }
    }
  }

  .projectPlanDetails_notes {
    position: relative;
    padding: 24px;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 500px;
    padding-bottom: 80px;

    &::-webkit-scrollbar {
      width: 4px; // Set the width of the scrollbar
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important; // Background of the scrollbar track
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ced0d4 !important; // Color of the scrollbar thumb
      border-radius: 10px; // Rounded corners
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #aeb0b5 !important; // Darker color on hover
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
      color: #171717;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 24px;
      margin-bottom: 16px;
    }

    .noItems {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;

      h5 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        color: #737373;
      }
    }

    .notesItems {
      display: flex;
      flex-direction: column;
      gap: 30px;
      overflow: auto;
      max-height: 300px;
      min-height: 300px;
      padding-right: 16px;

      scrollbar-width: auto;
      scrollbar-color: #ced0d4 #f5f5f5;
      *::-webkit-scrollbar {
        width: 10px;
      }

      *::-webkit-scrollbar-track {
        background: #f9f9f9;
        border-radius: 10px;
      }

      *::-webkit-scrollbar-thumb {
        border: 3px solid #f9f9f9;
        background: #1689ca;
        border-radius: 10px;
      }

      .notesItem {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        gap: 4px;

        h5 {
          margin: 0;
          padding: 0;
          font-size: 14px;
          font-weight: 400;
          color: #171717;
          font-family: "Inter";
          margin-bottom: 4px;
          max-width: 350px;
        }

        span {
          color: #a3a3a3;
          font-weight: 400;
          font-size: 14px;
        }

        .notes_actions {
          display: flex;
          gap: 20px;

          svg {
            cursor: pointer;
          }
        }
      }
    }

    .addNote {
      width: 100%;
      margin-top: 30px;
      display: flex;
      gap: 8px;
      position: absolute;
      bottom: 24px;
      // left: 24px;
      left: 0;
      width: 100%;
      padding: 0 24px;

      input {
        width: 100%;
        border: 1px solid #e5e5e5;
        padding: 10px 12px;
        border-radius: 4px;
        font-size: 14px;
        font-family: "Inter";
        font-weight: 400;
        color: 171717;

        ::placeholder {
          color: #a3a3a3;
        }
      }

      button {
        width: 44px;
        background-color: #1271a6;
        color: #fff;
        border: none;
        border-radius: 2px;
        padding: 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
    }
  }
}

.projectPlanDetails_actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 30px 20px;
  background-color: #fff;

  button {
    border: none;
    outline: none;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  button:nth-child(1) {
    background-color: #fff;
    color: #1271a6;
    border: 1px solid #1271a6;
    padding: 8px 16px;
  }

  button:nth-child(2) {
    background-color: #1271a6;
    color: #fff;
    padding: 8px 26px;
  }

  button:nth-child(3) {
    background-color: #ff9900;
    color: #fff;
    padding: 8px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;

    p {
      margin: 0;
    }
  }
}

.ai_popover {
  .ai_popoverHeader {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #d9d9d9;
  }

  .ai_popoverContent {
    h3 {
      font-size: 16px;
      font-weight: 700;
      color: #000000;
      margin: 0;
      padding: 0;
      margin-bottom: 12px;
    }

    h6 {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      margin: 0;
      padding: 0;
      margin-bottom: 12px;
    }
    .ai_popoverAction {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1271a6;
        color: #fff;
        border: none;
        border-radius: 3px;
        padding: 8px 12px;
        font-weight: 500;
        font-size: 12px;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
    }
  }
}
