.inputGroup {
  position: relative;
  display: flex;
  gap: 40px;

  .label {
    font-size: 14px;
    font-weight: 500;
    color: #737373;
    margin-top: 10px;
    width: 130px;
  }

  .inputWrapper {
    position: relative;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .inputContainer {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid #e5e5e5;
      background-color: #ffffff;
      border-radius: 4px;

      textarea {
        border: none;
        background: none;
        width: 100%;
        padding: 10px;
        font-weight: 400;
        font-size: 14px;
        font-family: "Inter";
        color: #171717;
        min-height: 40px;
        height: 40px;
        padding-top: 12px;
        margin-right: 45px !important;

        resize: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        box-sizing: border-box;

        &:focus,
        &:active {
          margin-right: 40px;
        }

        &:focus + .threedots,
        &:active + .threedots {
          display: none;
        }
      }

      .threedots {
        position: absolute;
        right: 30px;
        top: 10px;
        cursor: pointer;
      }

      .ai_Icon {
        position: absolute;
        right: 12px;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        border: 2px solid #1271a6;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease-in-out;
      }

      .resizeIcon {
        position: absolute;
        right: 1px;
        bottom: 3px;
        cursor: ns-resize;
      }

      &:hover .ai_Icon {
        opacity: 1;
        visibility: visible;
      }

      &:focus-within .ai_Icon {
        opacity: 1;
        visibility: visible;
      }
    }

    .wordCount {
      margin-top: 4px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 10px;
      font-size: 13px;
      color: #737373;
      font-weight: 400;
      font-family: "Inter";
    }
  }

  .error {
    font-family: "Roboto";
    font-size: 12px;
    margin-top: 4px;
    color: #ff0000;
  }
}
