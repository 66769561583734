.NewHeader {
  width: 100%;
  background-color: #fff;
  // margin-bottom: 10px;

  .NewHeader_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px;
    border-bottom: 1px solid #c1cad1;

    .title {
      font-size: 16px;
      font-weight: 600;
      font-family: "Inter";
      margin: 0;
      color: #1271a6;
      text-transform: uppercase;
    }

    .NewHeader_top_actions {
      display: flex;
      align-items: center;
      gap: 24px;

      .action {
        color: #4c545b;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: "Inter";
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }

  .NewHeader_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #c1cad1;

    .NewHeader_bottom_left,
    .NewHeader_bottom_right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .NewHeader_bottom_left {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        font-size: 12px;
        font-weight: 500;
        padding: 8px 12px;
        span {
          margin: 0;
        }
      }

      button:nth-child(1) {
        background-color: #ff9900;
        border: 1px solid #ff9900;
        color: #fff;

        &:hover {
          background-color: #fff;
          border: 1px solid #ff9900;
          color: #ff9900;
        }
      }

      button:nth-child(2) {
        border: 1px solid #ff9900;
        color: #ff9900;

        &:hover {
          background-color: #ff9900;
          border: 1px solid #ff9900;
          color: #fff;
        }
      }
    }

    .NewHeader_bottom_right {
      .button {
        padding: 0px 8px;
        font-size: 12px;
        font-weight: 500;
        color: #4c545b;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        cursor: pointer;
      }

      .taskActive {
        background-color: #418db8;
        border-radius: 20px;
        color: #ffffff;
        padding: 4px 6px;
      }
      .searchWrapper {
        width: 200px;
        position: relative;
        background-color: #f5f5f5;
        border: 1px solid #e5e5e5;
        padding: 8px 12px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 35px;

        input {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          font-family: "Inter";
          color: #737373;
          border: none;
          outline: none;
          background: transparent;
        }

        .icon {
          right: 10px;
          position: absolute;
        }
      }
    }
  }
}

.loader {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 99999;
}

.centered {
  position: fixed;
  top: 55%;
  width: 27%;
  height: 27%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  display: flex;
  // margin: -7px;
  flex-wrap: wrap;
  padding: 10px;
}

.status {
  color: #404040;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
  margin-right: 10px;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    color: #a3a3a3;
  }
}

.taskCardView_wrapper {
  padding: 20px 10px 20px 30px;
  width: 100%;
  display: flex;
  gap: 10px;
  overflow: auto;
}
