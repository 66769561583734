.wrapperModal {
  display: flex;
  flex-direction: column;
  max-width: 1052px;
  width: 100%;
  height: 100vh;
  max-height: 682px;
  position: relative;

  .header {
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #CDCDCD;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 16px;
      color: #06203a;
    }

    .close {
      width: 28px;
      height: 28px;
      border: 1px solid #fff;
      background: #CDCDCD;
      border-radius: 6px;
      padding: 0;
      outline: none;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .LeftSide {
    background-color: #FFFF;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    border-right: 1px solid #eee;
  }

  .box {
    width: 90%;
  }

  .label {
    font-weight: 500;
    font-size: 15px;
    color: #737373;
    margin-top: 5px;
  }

  .inputGroup {
    margin-bottom: 0px !important;
  }

  .FormInput_formControl__QJNvT {
    border: 1px solid transparent
  }

  .RightSide {
    padding: 20px;

    .headerRight {
      border-bottom: 1px solid #E5E5E5;
      padding-bottom: -20px;
      display: flex;
      align-items: center;
    }

    .activeHeading {
      font-weight: 600;
      font-size: 14px;
      color: #FEAB3D;
      cursor: pointer;
      transition: color 0.3s ease-in-out;
      border-bottom: 1px solid #FEAB3D;
      width: fit-content;
      margin: 0%;
      padding: 10px;
    }

    .unActiveHeading {
      font-weight: 600;
      font-size: 14px;
      color: #737373;
      margin-bottom: 0%;
      cursor: pointer;
    }

    .name {
      margin: 0%;
      color: #1B2A3D;
      font-size: 12px;
      font-weight: 500;
    }

    .DateTime {
      margin: 0;
      color: #A3A3A3;
      font-size: 12px;
    }

    .para {
      color: #1B2A3D;
      font-size: 12px;
      margin: 0;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 20px;
    border: 1px solid #e3e5e9;
    background-color: #fff;

    .btn {
      display: flex;
      align-items: center;
      outline: none;
      padding: 0;
      border: none;
      background-color: transparent;

      .next_icon {
        width: 28px;
        height: 28px;
        background: #ecf3f7;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        cursor: pointer;
        border: none;
      }

      figure {
        margin: 0 10px 0 0;
      }

      .disabled {
        cursor: not-allowed;
        background-color: rgba(175, 175, 175, 0.3) !important;

        svg {
          path {
            stroke: rgba(179, 175, 175, 0.7);
          }
        }
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #1271a6;
        display: block;
      }
    }

    .reverse_btn {
      flex-direction: row-reverse;

      figure {
        margin: 0 0 0 10px;
      }
    }

    .save_btn {
      background-color: "#1271A6";
      color:white;
      width: 88px;
      border: 1px solid #1271A6;
      border-radius:2px;
    }
  }
  textarea{
    background-color:"#fff";
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    min-height: 84px;
    width: 100% !important;
    margin-bottom: 10px;
    vertical-align: text-top !important;
    resize: none;
    padding: 10px 15px;
  }
}

.CancelBtn {
  background-color: transparent;
  border: 1px solid #71AACA;
  color: #71AACA;
  width: 88px;
  height: 36px
}

.saveBtn {
  background-color: #71AACA;
  border: unset;
  color: white;
  width: 88px;
  height: 36px
}
