* {
  box-sizing: border-box;
}

.body {
  display: block;
  overflow: hidden;

  :global(.ant-layout-content) {
    overflow: hidden;
  }
  .mainContainer {
    background-color: rgb(255, 255, 255);
    height: 72vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    overflow-y: auto;
    scroll-behavior: smooth;
    border-radius: 5px;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #ffffff !important;
      border-radius: 6px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ccd3dc !important;
      border-radius: 6px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #ffffff !important;
    }
    .templateContainer {
      width: 100%;
      height: inherit;
      padding: 20px;
      overflow-y: scroll;

      h2 {
        display: flex;
        margin-right: auto;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
        padding-top: 10px;
      }
      .customForm {
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 5px 8px;
        margin-bottom: 10px;
        min-height: 30vh;
        height: auto;

        .container {
          width: 100%;
          padding: 2px;

          .inputField {
            display: flex;
            flex-direction: column;
            .duration_container {
              padding-left: 8px;
              .warning {
                color: red;
              }
            }
            label {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #737373;
            }
          }
          .inputField_collaborator {
            display: flex;
            flex-direction: column;
            width: 90%;
            margin-right: auto;
            label {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #737373;
            }
          }
          .btnContainer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .btnSubmit {
              outline: none;
              border: none;
              background: #ff9900;
              border-radius: 6px;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #fff;
              padding: 10px 16px;
              text-transform: uppercase;
              cursor: pointer;
              display: flex;
              align-items: center;

              &.secondary {
                background: #1271A6;
                margin-right: 18px;
              }
            }
          }
        }
      }
      .customDecisionContainer {
        width: 100%;
        height: auto;
        min-height: 35.5vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .create {
          outline: none;
          border: none;
          background: #ff9900;
          border-radius: 6px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          padding: 10px 16px;
          text-transform: uppercase;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #878787;
          margin: 0.75rem 0;
        }
        h3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #000000;
        }
      }
      .decisionTemplateContainer {
        background-color: rgb(255, 255, 255);
        width: 100%;
        // min-height: 40vh;
        height: auto;

        // scroll-behavior: smooth;

        // ::-webkit-scrollbar {
        //   width: 6px;
        // }

        // ::-webkit-scrollbar-track {
        //   background: #ffffff !important;
        //   border-radius: 6px;
        // }

        // /* Handle */
        // ::-webkit-scrollbar-thumb {
        //   background: #ccd3dc !important;
        //   border-radius: 6px;
        // }

        // /* Handle on hover */
        // ::-webkit-scrollbar-thumb:hover {
        //   background: #ffffff !important;
        // }
        .border_top {
          width: 96%;
          display: flex;
          margin: auto;
          border-top: 1px solid #d9d9d9;
          padding: 4px;
        }
        .container {
          // min-height: 40vh;
          height: auto;

          padding: 10px 0;

          .decision_container {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
  .error{
    color: red;
    left: 0;
    display: block;
    opacity: 0.8;
    font-size: 12px;
  }
}
