.background,
.modal {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 81px);
  top: 0;
  left: 0;
  margin-top: 81px;

  @media (max-width: 1300px) {
    height: calc(100vh - 56px);
    margin-top: 56px;
  }
}

.background {
  background: rgba(#000, 0.5);
  z-index: 88;
}

.modal {
  position: fixed;
  display: flex;
  z-index: 90;
  overflow-y: auto;
  padding: 36px 0;
}

.wrapper {
  width: 100%;
  // max-width: 744px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 20px rgba(27, 42, 61, 0.06);
  margin: auto;

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 73px;
    background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 4px 4px 0 0;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: white;
    }
  }

  .body {
    display: flex;
    padding: 30px;
    width: 100%;

    .column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 60px;

      .title {
        padding: 14px 10px;
      }
    }

    .column2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 402px;

      .title {
        padding: 14px;
      }
    }

    .column3 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 80px;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;

    button {
      background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
      border-radius: 4px;
      padding: 8px 12px;
      color: white;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      border: none;
      text-transform: uppercase;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

.box {
  padding: 16px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  height: 56.99px;
  border-bottom: 1px solid #e6e7eb;
  border-right: 1px solid #e6e7eb;
&.boxstyle{
  overflow: hidden;
  max-height: 56.99px;
  height: 100%;
  display: block;
  padding: 5px 0;
  p{
    display: flex;
    margin: 5px 0;
    height: 100%;
    overflow: auto;
    padding: 0 15px;
    &::-webkit-scrollbar{
      display: none;
    }
  }
}
  &.winner {
    background: linear-gradient(
        0deg,
        rgba(254, 171, 61, 0.1),
        rgba(254, 171, 61, 0.1)
      ),
      #ffffff;
    color: #feab3d;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    border-bottom: 1px solid #e6e7eb;
    border-right: 1px solid #e6e7eb;
  }
}

.title {
  background: #2d8cc1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  width: 100%;
  color: #ffffff;
  height: 40px;
  padding: 6px 10px;
  border-right: 1px white solid;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px white solid;
  background-color: transparent;
  padding: 0;
}
