.customDropdown {
  position: absolute;
  top: 154px;
  background-color: #fff;
  width: 180px;
  z-index: 100 !important;
  min-height: 400px;
  max-height: 400px;
  padding: 8px 0px;
  box-shadow: 0px 2px 10px 0px rgba(10, 15, 28, 0.4);
  border-radius: 3px;
  overflow: hidden;

  .customDropdown-content {
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px; // Set the width of the scrollbar
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important; // Background of the scrollbar track
      z-index: -1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ced0d4 !important; // Color of the scrollbar thumb
      border-radius: 10px; // Rounded corners
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #aeb0b5 !important; // Darker color on hover
    }

    /* Firefox scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: #ced0d4 #f1f1f1; // Thumb and track color for Firefox
  }

  .custom-scrollbar {
    position: absolute;
    top: 0;
    right: 0px;
    width: 14px;
    height: 100%;
    // background-color: red;
    border-radius: 10px;
    z-index: 99999999; /* High z-index to appear on top */
  }

  .customDropdown-box {
    position: relative;

    .customDropdown-title {
      font-family: "Inter", sans-serif;
      font-weight: 600;
      font-size: 12px;
      color: #112538;
      margin: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 16px 0px 8px;
    }

    .customDropdown-Menus {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0px;
      margin: 0px;
      gap: 4px;
      padding-right: 12px;
      padding-left: 8px;
      // padding: 0px 14px;

      .customDropdown-Menu {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
        padding: 6px;
        padding-right: 0;
        color: #1b2a3d;
        cursor: pointer;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

.customDropdown-submenu {
  background-color: #fff;
  padding: 4px 12px 4px 4px;
  min-height: max-content;
  max-height: 300px;
  overflow: auto;
  width: 147px;
  position: absolute;
  z-index: 1000;
  box-shadow: 0px 2px 10px 0px rgba(10, 15, 28, 0.4);
  overflow: auto;
  border-radius: 3px;

  &::-webkit-scrollbar {
    width: 4px; // Set the width of the scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Background of the scrollbar track
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced0d4 !important; // Color of the scrollbar thumb
    border-radius: 10px; // Rounded corners
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #aeb0b5 !important; // Darker color on hover
  }

  /* Firefox scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #ced0d4 #f1f1f1; // Thumb and track color for Firefox

  .customDropdown-submenu-items {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    margin: 0px;
    gap: 4px;
    max-height: 300px;

    .customDropdown-submenu-item {
      font-family: "Inter", sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: #1b2a3d;
      padding: 6px 10px;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 4px;
      text-transform: uppercase;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.unit-selected {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 2px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 4px 10px;
  width: 80px;
  border-radius: 4px;

  h3 {
    color: #a3a3a3;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
    text-transform: capitalize;
    margin: 0;
    margin-left: 1px;
  }
}

.addMetricBtn {
  margin: 8px 2px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  font-weight: 500;
  color: #1271a6;
  font-size: 12px;
  font-family: "Inter", serif;
  cursor: pointer;

  &:hover {
    color: #0a4f8a;
  }
}

.metric-model-header {
  padding: 16px 12px;

  h3 {
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #1b2a3d;
    font-size: 14px;
  }
  svg {
    cursor: pointer;
    position: relative;
    top: -4px;
  }
}

.metric-model-body {
  padding: 0px 12px;
  max-height: 460px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  // gap: 12px;
}

.metric-model-footer {
  padding: 0px 12px;

  .footer-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 12px;
    gap: 16px;

    button {
      border: none;
      outline: none;
      padding: 6px 12px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 2px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

    button:nth-child(1) {
      background: none;
      color: #1271a6;
    }

    button:nth-child(2) {
      background-color: #4b8021;
      color: #ffffff;
    }
  }

  .footer-addMetricBtn {
    padding-bottom: 16px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #ced0d4;
  }
}

.customDropdown-open {
  display: block;
}

.customDropdown-close {
  display: none;
}

.empty-metric {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #263242;
  font-family: "Inter", sans-serif;
}