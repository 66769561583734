.datePicker_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .datepicker {
    font-size: 14px !important;
    padding: 0;
    border: none;
    background: none !important;
    box-shadow: none !important;

    :global(.ant-picker-input input) {
      width: 70px;
    }
  }

  .closeIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    .closeIcon {
      visibility: visible;
      opacity: 1;
    }
  }
}

.customDropdownSelect {
  height: 300px;

  width: 150px !important;
}

.customDropdownSelect .ant-select-item-option-content {
  width: 150px !important;


}

.customDropdownSelect .sub-option-dropdown {
  min-width: 150px !important;


}



.measurement-popover-item .ant-dropdown-menu {
  width: 150px;
}




.dropdownTrigger {
  cursor: pointer;
  font-size: 14px;
  color: #1271a6;
  background-color: #ecf3f6;
  padding: 8px 12px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.addMetric {
  color: #1271a6;
  cursor: pointer;
  font-size: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

}

.customDropdownMenu {
  box-shadow: 0 0 10px #00000024;
  padding: 10px 5px 5px 5px;
  background-color: #fff;
  width: 140px;
  
  .inner {
    overflow: auto;
    max-height: 200px;
   &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
      z-index: -1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ced0d4 !important;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #aeb0b5 !important;
    }

    // scrollbar-width: thin;
    scrollbar-color: #ced0d4 #f1f1f1;
  }

  .lastItem {
    text-align: end;
    padding-top: 5px;
    padding-right: 10px;
    font-weight: 500;
    color: #1271a6;
    font-size: 12px;
  }

  :global(.ant-dropdown-menu-submenu-title) {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    padding: 6px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .ant-dropdown-menu {
    box-shadow: none
  }
}