.wrapper {
  display: block;
  padding-bottom: 10vh;

  &.hiden {
    user-select: none;
    opacity: 0.5;
    pointer-events: none;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1271a6;
  height: 64px;
  padding: 0 20px;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .menu {
    color: white;
    border-bottom: 1px solid white;
  }

  .back {
    width: 20px;
    height: 20px;
    outline: none;
    border: none;
    background: transparent;
    margin-right: 16px;
  }

  .title {
    font-family: "Inter";
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    margin-right: 28px;
    color: #fff;
  }

  .create {
    outline: none;
    border: none;
    background: #ff9900;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    padding: 10px 16px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
  .searchInput {
    width: 24rem;
    border-radius: 2px;
    height: 48px;
    border: 1px solid var(--primary-300, #71aaca);
    background: var(--primary-500-main, #1271a6);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.1);

    input {
      width: 92%;
      height: inherit;
      padding: 10px;
      padding-right: 4px;
      background-color: transparent;
      border: none;
      color: var(--primary-300, #ffffff);
      /* Paragraph/Small/Regular */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    ::placeholder {
      color: var(--primary-300, #71aaca);
    }
  }
}

.searchWrapper {
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;

  .icon {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
}

.search {
  flex: 1;
  font-family: "Inter";
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 1px 2px rgba(254, 230, 230, 0.05);
  border-radius: 6px;
  padding: 11px 12px 11px 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &::placeholder {
    color: #a3a3a3;
  }
}
.outlet {
  position: absolute;
  top: 10vh;
  right: calc((100vw - 560px) / 2);
  z-index: -2;
  &.active {
    z-index: 2;
  }
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.decisions {
  display: flex;
  flex-wrap: wrap;
}
.popover {
  background: #0a58ca;
  color: white;
}
.popover:hover {
  background: #0d6efd;
}
.statusesWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 0px;
  width: 100%;

  .decision_container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  // .decision_templates {
  //   width: 331px;
  //   height: 121px;
  //   background: #ffffff;
  //   box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.12);
  //   border-radius: 4px;
  //   // padding: 10px;
  //   padding: 6px 10px 10px 5px;
  //   display: flex;
  //   justify-content: center;
  //   position: relative;
  //   display: flex;
  //   gap: 10px;
  //   .decision_gradient {
  //     width: 6px;
  //     height: 109px;
  //     background: linear-gradient(25.99deg, #7400cf 16.39%, #bc68fd 83.61%);
  //     border-radius: 4px;
  //   }
  //   .decision_header {
  //     width: 100%;
  //     height: auto;
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: flex-start;
  //     gap: 10px;
  //     .title {
  //       h5 {
  //         margin-bottom: 0;
  //       }
  //       a {
  //         font-family: "Inter";
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 12px;
  //         line-height: 12px;
  //         color: #6b7ddc;
  //         text-decoration: none;
  //         position: relative;
  //         bottom: 7px;
  //       }
  //     }
  //     .preview {
  //       position: relative;
  //       bottom: 4px;
  //       a {
  //         font-family: "Poppins";
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 12px;
  //         line-height: 20px;
  //         color: #418db8;
  //         text-decoration: none;
  //       }
  //     }
  //   }
  //   .decision_addition {
  //     width: 28px;
  //     height: 28px;
  //     background: #1271a6;
  //     border-radius: 3px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     position: absolute;
  //     bottom: 7px;
  //     right: 7px;
  //     span {
  //       color: #ffffff;
  //       font-size: 20px;
  //     }
  //   }
  // }
  .statuses {
    margin: 30px 15px 15px;
    margin-left: 0;
    display: flex;
    align-items: center;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
    }

    &.dark {
      span {
        color: #112538;
      }
    }

    .iconShow {
      margin: 0px 8px 0px;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
}
@media (max-width: 1199px) {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 8px 0;
    width: 100%;
    justify-content: center;
  }
  .header {
    height: 69px;
    padding: 4px 0px 6px 16px;
    border-radius: 0;
    position: relative;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    //width: 90vw;
    .titleWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
    }
    .title {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .menu {
      color: white;
      border-bottom: 1px solid white;
    }

    .back {
      width: 20px;
      height: 20px;
      outline: none;
      border: none;
      background: transparent;
      margin-right: 16px;
    }

    .create {
      width: 126px;
      height: 36px;
      font-size: 12px;
      line-height: 20px;
      padding: 8px 16px;
    }
  }

  .sort {
    //width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: nowrap;
    // position: relative;
    width: 100%;
    left: 33px;
    font-size: 12px;
    line-height: 20px;
  }

  .outlet {
    position: absolute;
    top: 10vh;
    right: calc((100vw - 560px) / 2);
    z-index: -2;
    &.active {
      z-index: 2;
    }
  }
  .statusesWrapper {
    margin: 12px 0 5px 16px;
    padding: 0 0 0 0;
    .statuses {
      margin: 0 0 0 0;
      padding: 14px 0 0;
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .decisions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.gallery {
  font-family: Poppins;
  font-size: 20px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: white;
  width: 100%;
  border-bottom: 2px solid white;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
