.compareWrapper {
  width: 100%;
  background: white;
  border-radius: 4px;
  min-height: 200px;

  .compareTextDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    .compareText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #1b2a3d;
    }

    .generateAI {
      font-family: "Inter";
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #1271a6;
    }
  }

  .consrequired {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #ff0000;
    margin-left: 15px;
  }

  .compareTitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #1b2a3d;
  }

  .compareTablet {
    display: flex;
    width: 100%;
    // min-height: 120px;
    max-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 4px;
    border: 1px solid #e3e5e9;
    .column_separater {
    }
    .column {
      display: flex;
      flex-direction: column;
      width: 50%;
      // max-width: 575px;
      align-items: flex-start;
      position: relative;
      box-sizing: border-box;
      min-height: 120px;

      // .header {
      //   width: 100%;
      //   height: 44px;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   border-top-right-radius: 4px;
      //   border-top-left-radius: 4px;

      //   span {
      //     font-family: "Inter";
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 14px;
      //     line-height: 20px;
      //     color: white;
      //   }
      // }
      .header_pros {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 10px 10px 10px 16px;
        height: 52px;
        background: #89c740;
        border-right: 1px solid #f5f5f5;
        border-radius: 4px 0 0 0;
        h5 {
          margin: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
        button {
          width: 92px;
          height: 32px;
          padding: 6px 12px;
          background: #d4f8ab;
          border-radius: 6px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #458c0a;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.4rem;
          border: none;
        }
      }
      .header_cons {
        width: 100%;
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 10px 10px 10px 16px;
        background: #ff8071;
        border-radius: 0 4px 0 0;
        h5 {
          margin: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
        button {
          width: 100px;
          height: 32px;
          padding: 6px 12px;
          background: #ffebdc;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.4rem;
          border: none;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #ff8071;
        }
      }
      .prosWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        // padding: 0 16px;

        .br {
          color: silver;
          height: 1px;
          background: 1px;
          margin: 10px 16px;
        }
      }

      .popover_container {
        // margin-top: 10px;
        span {
          svg {
            fill: #a3a3a3;
            font-size: 18px;
          }
        }
      }

      .pros {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        padding: 10px 10px 10px 16px;
        overflow: hidden;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #f5f5f5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // gap: 1rem;
        .details {
          display: flex;
          align-items: flex-start;
          // align-items: center;
          gap: 10px;
          width: calc(100% - 90px);
          textarea{
            width: 100%;
          }
        }
        div {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #858e99;
        }

        .context {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          // margin-left: 10px;
          word-break: break-word;
          // max-width: 90%;
          color: #404040;
        }
      }

      .buttonAdd {
        width: 100%;
        padding: 15px 30px;
        display: flex;
        // width: 170px;
        justify-content: flex-end;

        .button {
          display: flex;
          align-items: center;
          gap: 10px;
          border: none;
          background-color: transparent;

          &:not(:disabled) {
            cursor: pointer;
          }

          &:disabled {
            cursor: not-allowed;
          }

          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .input {
        //margin-top: 10px;
        width: 90%;
        border-radius: 4px;
        border: 1px solid #e3e5e9;
        padding: 8px 12px;
        // margin: 15px 16px 10px;
        &::placeholder {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }
      }
      .inputPro {
        width: 90%;
        border-radius: 4px;
        border: 1px solid #e3e5e9;
        // margin: 15px 16px 10px;
        // resize: none;
        overflow: hidden;
        overflow-y: scroll;
        // white-space:nowrap;
        padding: 8px 12px;
        &::placeholder {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }
      }
      .editPros {
        //margin-top: 10px;
        width: 90%;
        border-radius: 4px;
        border: 1px solid #e3e5e9;
        padding: 8px 12px;
        overflow: hidden;
        overflow-y: scroll;
        // margin: 15px 16px 10px;
        &::placeholder {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }
      }
      .inputCons {
        width: 90%;
        border-radius: 4px;
        border: 1px solid #e3e5e9;
        padding: 8px 12px;
        margin: 15px 16px 10px;
        overflow: hidden;
        overflow-y: scroll;
        &::placeholder {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}

.dropdown {
  :global(.ant-popover-inner) {
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
  }

  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}

.item {
  display: flex;
  flex-direction: column;
  z-index: 99;
  background-color: white;
  button {
    font-family: "Poppins";
    font-weight: 500;
    background: transparent;
    color: #4c545b;
    font-size: 12px;
    line-height: 16px;
    padding: 12px 20px;
    border: none;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 1px solid #e6e7eb;

    &:hover {
      background: #e6e7eb;
    }
  }
}

.consMitigationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.mitigation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #858e99;
  margin: 10px 0 0 20px;
  display: flex;

  word-break: break-word;
  //width: 94%;
  max-width: 90%;
  overflow: hidden;
  //justify-content: ;
  div {
    max-width: 80%;
  }
}
.inputMitigation {
  width: 90%;
  border-radius: 4px;
  border: 1px solid #e3e5e9;
  padding: 8px 12px;
  margin: 10px 16px 20px;
  overflow: hidden;
  overflow-y: scroll;
  //height: 30px;
  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
}
.dot {
  margin-left: 10px;
  transform: rotate(90deg);
  font-weight: bold;
  font-size: 14px;
}
.form {
  height: 400px;
}
.centered {
  position: fixed;
  right: 45vw;
  //margin: 0 48vw 0 0;
}
@media (max-width: 767px) {
  //.compareWrapperMobile {
  //width: 100%;
  //background: white;
  //border-radius: 4px;
  //min-height: 200px;
  //
  //.compareTitleMobile {
  //  font-family: 'Inter';
  //  font-style: normal;
  //  font-weight: 500;
  //  font-size: 16px;
  //  line-height: 20px;
  //  margin-bottom: 12px;
  //  color: #1B2A3D;
  //  margin-left: 10px;
  //}
  //
  .compareMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    //min-height: 120px;
    .prosConsTitleWrp {
      display: flex;
      .headerMobile {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #c4c4c4;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: white;
        }
      }
    }
    .columnMobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      border-radius: 4px;
      border: 1px solid #e3e5e9;
      position: relative;
      box-sizing: border-box;
      min-height: 120px;

      .prosWrapperMobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 16px;

        .br {
          color: silver;
          height: 1px;
          background: 1px;
          margin: 10px 16px;
        }
      }

      .prosMobile {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        margin: 10px 0 0;
        overflow: hidden;

        div {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #858e99;
        }

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-left: 10px;
          word-break: break-word;
          max-width: 90%;
        }
      }

      .buttonAddMobile {
        margin: 15px 30px;
        display: flex;
        width: 170px;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          margin-left: 10px;
        }
      }

      .inputMobile {
        //margin-top: 10px;
        width: 90%;
        border-radius: 4px;
        border: 1px solid #e3e5e9;
        padding: 8px 12px;
        margin: 15px 16px 15px;
        &::placeholder {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  //}
  .itemMobile {
    min-width: 80px;
    //position: absolute;
    //z-index: 100000;

    div {
      border-radius: 4px;
      width: 100%;
      padding: 12px;
    }

    div:hover {
      background: #a1a1a4;
    }
  }
  .consMitigationWrapperMobile {
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: center;
    width: 100%;
  }
  .mitigationMobile {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #858e99;
    margin: 10px 0 0 20px;
    display: flex;
    width: 94%;
    //justify-content: ;
  }
  .inputMitigationMobile {
    width: 90%;
    border-radius: 4px;
    border: 1px solid #e3e5e9;
    padding: 8px 12px;
    margin: 5px 16px 20px;
    //height: 30px;
    &::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .dotMobile {
    margin-left: 10px;
    transform: rotate(90deg);
    font-weight: bold;
    font-size: 14px;
  }
  .formMobile {
    height: 400px;
  }
  .centeredMobile {
    position: fixed;
    right: 45vw;
    //margin: 0 48vw 0 0;
  }
}
