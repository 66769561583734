.criteria {
  padding: 24px;
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 500px;
  max-height: 100%;
  min-height: 440px;

  h2 {
    font-size: 20px;
    font-weight: 500;
    color: #171717;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 24px;
    margin-bottom: 8px;
  }

  .criteria_head {
    padding-bottom: 16px;

    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 8px;

      li {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        display: flex;
      }

      li:nth-child(1) {
        width: 50%;
      }

      li:nth-child(2) {
        width: 40%;
      }

      li:nth-child(3) {
        align-items: center;
        justify-content: flex-end;
        width: 20%;
      }
    }
  }

  .criteria_body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    max-height: 120px;
    padding-right: 12px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
      z-index: -1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ced0d4 !important;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #aeb0b5 !important;
    }

    scrollbar-width: thin;
    scrollbar-color: #ced0d4 #f1f1f1;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin: 0;
        padding: 0;
      }

      .criteria_tag {
        width: 50%;

        .criteria_tag_value {
          padding: 4px 10px;
          font-size: 12px;
          font-weight: 400;
          border-radius: 20px;
          color: #fff;
          width: max-content;
          max-width: 180px;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          word-break: break-word;
          -webkit-line-clamp: 1;
        }
      }

      .criteria_value {
        font-size: 14px;
        font-weight: 400;
        color: #171717;
        width: 40%;

        span {
          font-size: 14px;
          font-weight: 400;
          color: #171717;
          width: max-content;
        }
      }

      .criteria_action {
        font-size: 14px;
        font-weight: 500;
        color: #1271a6;
        display: flex;
        gap: 12px;
        cursor: pointer;
        width: 20%;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .criteria_create {
    width: max-content;
    font-size: 14px;
    font-weight: 500;
    color: #1271a6;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    margin-bottom: 24px;
    // padding-top: 20px;
    margin-top: 20px;
  }
}

.criteria_popover {
  width: 280px;
  padding: 18px 16px;

  .criteria_popover_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    h3 {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      color: #1b2a3d;
    }

    svg {
      cursor: pointer;
    }
  }

  .criteria_popover_input {
    display: flex;
    flex-direction: column;
    gap: 6px;

    label {
      color: #737373;
      font-weight: 500;
      font-size: 14px;
      font-family: "Inter";
    }

    input {
      border: 1px solid #e5e5e5;
      color: #fff;
      padding: 8px 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #171717;
      font-family: "Inter";
    }
  }

  .criteria_popover_color_section {
    display: flex;
    margin: 25px 0px;
    gap: 26px;
    align-items: center;
    justify-content: space-between;

    .criteria_popover_hash {
      width: 36px;
      height: 36px;
      background-color: #1271a6;
      border-radius: 4px;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 26px;
    }

    .criteria_popover_colorLists {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 6px;
      flex-wrap: wrap;
      justify-content: flex-end;

      .criteria_popover_color {
        display: flex;
        border-radius: 100%;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        padding: 2px;

        .criteria_popover_color_cricle {
          width: 12px;
          height: 12px;
          border-radius: 100%;
        }
      }
    }
  }
}

.criteria_popover_actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid #edeef2;
  padding-top: 20px;
  padding: 20px 16px;

  button {
    padding: 8px 12px;
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
  }

  button:nth-child(1) {
    background-color: #ecf3f6;
    color: #1271a6;
  }

  button:nth-child(2) {
    background-color: #1271a6;
    color: #fff;
  }
}
