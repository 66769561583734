.toggleMenuContainer {
  border-bottom: 1px solid #f5f5f5;
  background-color: #fafafa;
  user-select: none;
  margin-bottom: 12px;

  .toggleHeader {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    div:nth-child(1) {
      cursor: pointer;
    }

    div:nth-child(2) {
      transform: scaleY(1);
    }

    div {
      display: flex;
      align-items: center;
      gap: 10px;
      transition: 0.3s all;

      h3 {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        color: #1271a6;
        cursor: pointer;
        margin-left: 6px;
      }

      .icon {
        transition: transform 0.3s ease;
        cursor: pointer;
      }
    }
  }

  &.collapsed {
    background-color: #fff;
    border-bottom: none;
    margin-bottom: 0px;

    div {
      div:nth-child(2) {
        transform: scaleY(0);
      }

      h3 {
        color: #1b2a3d;
      }
      .icon {
        transform: rotate(-90deg);
      }
    }
  }
}

.toggleContent {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding: 2px 16px;

  .metric,
  .unit {
    h3 {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      color: #737373;
      font-family: "Inter", serif;
      margin-bottom: 6px;
    }

    .metricInput,
    .unitInput {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;

      input {
        width: 100%;
        padding: 6px 12px;
        border: none;
        outline: none;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        color: #171717;
        font-size: 14px;
        font-family: "Inter", serif;
      }
    }

    .metricInput {
      margin-right: 25px;
    }
  }

  .addUnitBtn {
    margin-top: 16px;
    margin-right: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    font-weight: 500;
    color: #1271a6;
    font-size: 12px;
    font-family: "Inter", serif;

    div {
      cursor: pointer;
    }

    &:hover {
      color: #0a4f8a;
    }
  }
}

.error {
  color: red;
  font-size: 12px;
  font-weight: 500;
  margin: -3px 0px 12px 6px;
}
