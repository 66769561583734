.background,
.modal {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 75px);
  top: 0;
  left: 0;
  margin-top: 75px;
}

.background {
  background: rgba(#000, 0.5);
  z-index: 88;
}

.modal {
  position: fixed;
  display: flex;
  z-index: 90;
  overflow-y: auto;
  padding: 36px 0;
}

.wrapper {
  width: 100%;
  max-width: 744px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 20px rgba(27, 42, 61, 0.06);
  margin: auto;

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 73px;
    background: linear-gradient(180deg, #1263DE 0%, #448FFF 76.56%);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 4px 4px 0 0;

    span {
      display: inline-flex;
      align-items: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: white;

      svg {
        margin-right: 9px;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 400px;
    background: #fff;
    border-radius: 0 0 4px 4px;
    position: relative;

    .loader {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .decision {
      padding: 24px 0;
      color: #1271A6;
      font-family: 'Inter';
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      border-bottom: 1px solid #EDEEF2;
      margin-bottom: 15px;
      text-align: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 54px;
      position: relative;

      .lottie svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      .img {
        margin-bottom: 14px;
        border-radius: 4px;
      }

      .option {
        font-family: 'Inter';
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin: 0px 50px 20px 50px
      }

      .scores {
        display: flex;
        margin-bottom: 54px;

        p {
          font-family: 'Inter';
          margin: 0 8px;
          color: #525252;
          font-size: 14px;
          line-height: 17px;

          span {
            color: #3090F1;
            font-weight: 500;
          }
        }
      }

      .copyright {
        font-family: 'Poppins';
        color: #1271A6;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;

    button {
      background: linear-gradient(180deg, #1263DE 0%, #448FFF 76.56%);
      border-radius: 4px;
      padding: 8px 12px;
      color: white;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      border: none;
      text-transform: uppercase;
    }
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px white solid;
  background-color: transparent;
  padding: 0;
}
