.projectPlanDetails {
  width: 100%;
  display: flex;
  gap: 25px;
  position: relative;

  .projectPlanDetails_form {
    width: 100%;
    height: 100%;
    padding: 24px;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    .projectPlanDetails_header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 30px;
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #f5f5f5;

      h2 {
        margin: 0;
        padding: 0;
        color: #262626;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .form {
      width: 100%;
      justify-content: space-between;
      display: flex;
    }

    .form_left {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-right: 24px;
      padding-right: 24px;
      border-right: 1px solid #f5f5f5;
    }

    .form_right {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .collaborators,
    .techStack,
    .documents {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      border: 1px solid #f5f5f5;
      border-radius: 6px;

      .collaborators_heading,
      .techStack_heading,
      .documents_heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .actions {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        h3 {
          margin: 0;
          padding: 0;
          color: #404040;
          font-size: 16px;
          font-weight: 500;
        }

        svg {
          cursor: pointer;
        }

        .actionButton {
          color: #1271a6;
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
      }

      .collaborators_list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        .collaborators_avatar {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.15),
            0px 1px 3px 0px rgba(16, 24, 40, 0.15);
          background: #b9c9da;
          color: #fff;
          font-size: 16px;
        }
      }

      .techStack_lists {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        .techStack_list {
          color: #404040;
          font-size: 14px;
          font-weight: 500;
          background-color: #f5f5f5;
          border-radius: 24px;
          padding: 6px 12px;
        }
      }

      .documents_lists {
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;

        .documents_list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;

          .documents_list_left {
            display: flex;
            align-items: center;
            gap: 12px;

            .documents_icon {
              width: 40px;
              height: 40px;
              border-radius: 100%;
              background-color: #ecf3f6;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .documents_content {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 4px;

              h3 {
                margin: 0;
                padding: 0;
                color: #171717;
                font-size: 16px;
                font-weight: 400;
              }

              span {
                color: #737373;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
        }
      }

      .nodataAdd {
        font-size: 14px;
        color: #a3a3a3;
        font-weight: 400;
      }
    }

    .duration {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        width: 120px;
      }

      h6 {
        margin: 0;
        padding: 0;
        color: #a3a3a3;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .inputGroup {
      display: flex;
      gap: 40px;

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        margin-top: 10px;
        width: 120px;
      }

      .selectWrapper {
        background-color: #fff;
        border: 1px solid #e5e5e5;
      }

      .datePickerWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .inputWrapper,
      .selectWrapper {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;

        input {
          width: 100%;
          border: 1px solid #e5e5e5;
          background-color: #ffffff;
          border-radius: 4px;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          font-family: "Inter";
          color: #171717;
        }
      }

      .error {
        font-family: "Roboto";
        font-size: 12px;
        margin-top: 4px;
        color: #ff0000;
      }
    }
  }

  .projectPlanDetails_notes {
    padding: 24px;
    width: 530px;
  }
}
