.projectPlanDetails {
  width: 100%;
  display: flex;
  gap: 25px;

  .projectPlanDetails_left {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .projectPlanDetails_form {
      width: 100%;
      padding: 24px;
      box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
      background-color: #fff;

      .projectPlanDetails_header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 30px;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #f5f5f5;

        h2 {
          margin: 0;
          padding: 0;
          color: #262626;
          font-size: 20px;
          font-weight: 500;
        }

        .projectPlanDetails_form_actions {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;

          button {
            border: 1px solid #1271a6;
            border-radius: 2px;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
            font-family: "Inter";

            &:disabled {
              cursor: not-allowed;
              opacity: 0.6;
            }
          }

          button:nth-child(1) {
            background-color: white;
            padding: 8px 16px;
            color: #1271a6;
          }

          button:nth-child(2) {
            background-color: #1271a6;
            padding: 8px 48px;
            color: #fff;
          }
        }
      }

      .form {
        width: 100%;
        justify-content: space-between;
        display: flex;
        // gap: 30px;
      }

      .form_left {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-right: 24px;
        padding-right: 24px;
        border-right: 1px solid #f5f5f5;
      }

      .form_right {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .duration {
        display: flex;
        align-items: center;

        .label {
          font-size: 14px;
          font-weight: 500;
          color: #737373;
          width: 120px;
        }

        h6 {
          margin: 0;
          padding: 0;
          color: #a3a3a3;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .inputGroup {
        display: flex;
        gap: 40px;

        .label {
          font-size: 14px;
          font-weight: 500;
          color: #737373;
          margin-top: 10px;
          width: 120px;
        }

        .selectWrapper {
          background-color: #fff;
          border: 1px solid #e5e5e5;
        }

        .datePickerWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .inputWrapper,
        .selectWrapper {
          flex: 1;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;

          input {
            width: 100%;
            border: 1px solid #e5e5e5;
            background-color: #ffffff;
            border-radius: 4px;
            padding: 10px;
            font-weight: 400;
            font-size: 14px;
            font-family: "Inter";
            color: #171717;
          }
        }

        .error {
          font-family: "Roboto";
          font-size: 12px;
          margin-top: 4px;
          color: #ff0000;
        }
      }
    }

    .projectPreferences {
      width: 100%;
      padding: 24px;
      box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 6px;

      .projectPreferences_title {
        margin: 0;
        padding: 0;
        color: #262626;
        font-size: 20px;
        font-weight: 500;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #f5f5f5;
      }

      .status {
        max-width: 400px;

        .status_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          margin-bottom: 15px;

          .status_header_content {
            display: flex;
            align-items: center;
            gap: 12px;

            h4 {
              padding: 0;
              margin: 0;
              color: #171717;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        .status_Threshold {
          margin: 0;
          padding: 0;
          color: #737373;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .status_select {
          margin-bottom: 25px;
        }
      }
    }

    .form {
      width: 100%;
      justify-content: space-between;
      display: flex;
      // gap: 30px;
    }

    .form_left {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-right: 24px;
      padding-right: 24px;
      border-right: 1px solid #f5f5f5;
    }

    .form_right {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .duration {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        width: 120px;
      }

      h6 {
        margin: 0;
        padding: 0;
        color: #a3a3a3;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .inputGroup {
      position: relative;
      display: flex;
      gap: 40px;

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        margin-top: 10px;
        width: 130px;
      }

      .selectWrapper {
        background-color: #fff;
        border: 1px solid #e5e5e5;
      }

      .datePickerWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .inputWrapper,
      .selectWrapper {
        position: relative;
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .inputContainer {
          position: relative;
          svg {
            position: absolute;
            right: 1px;
            bottom: 1px;
            cursor: pointer;
          }
        }

        input {
          width: 100%;
          border: 1px solid #e5e5e5;
          background-color: #ffffff;
          border-radius: 4px;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          font-family: "Inter";
          color: #171717;
          padding-right: 30px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 30px;
        }

        textarea {
          width: 100%;
          border: 1px solid #e5e5e5;
          background-color: #ffffff;
          border-radius: 4px;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          font-family: "Inter";
          color: #171717;

          resize: none; /* Disable manual resizing */
          overflow: hidden; /* Hide overflow for small inputs */
          transition: height 0.2s ease;
        }

        .wordCount {
          margin-top: 4px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 10px;
          font-size: 13px;
          color: #737373;
          font-weight: 400;
          font-family: "Inter";
        }
      }

      .error {
        font-family: "Roboto";
        font-size: 12px;
        margin-top: 4px;
        color: #ff0000;
      }

      .goal_input_wrapper {
        position: relative;
        flex: 1;
        width: 100%;

        .goal_input {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          background-color: #ffffff;
          margin: 0;
          padding: 0;
          padding: 10px;
          font-size: 14px;
          color: #a3a3a3;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          user-select: none;
          gap: 10px;

          .goal_input_selected {
            width: 100%;

            h3 {
              margin: 0;
              padding: 0;
              color: #171717;
              font-weight: 400;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              white-space: normal;
              word-break: break-word;
              -webkit-line-clamp: 1;
            }
          }
        }
      }
    }
  }

  .projectPlanDetails_notes {
    position: relative;
    padding: 24px;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 530px;

    &::-webkit-scrollbar {
      width: 4px; // Set the width of the scrollbar
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important; // Background of the scrollbar track
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ced0d4 !important; // Color of the scrollbar thumb
      border-radius: 10px; // Rounded corners
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #aeb0b5 !important; // Darker color on hover
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
      color: #171717;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 24px;
      margin-bottom: 16px;
    }

    .noItems {
      height: 540px;
      display: flex;
      align-items: center;
      justify-content: center;

      h5 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        color: #737373;
      }
    }

    .notesItems {
      display: flex;
      flex-direction: column;
      gap: 30px;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 250px;
      min-height: 250px;
      padding-right: 16px;

      scrollbar-width: auto;
      scrollbar-color: #ced0d4 #f5f5f5;

      *::-webkit-scrollbar {
        width: 10px;
      }

      *::-webkit-scrollbar-track {
        background: #f9f9f9;
        border-radius: 10px;
      }

      *::-webkit-scrollbar-thumb {
        border: 3px solid #f9f9f9;
        background: #1689ca;
        border-radius: 10px;
      }

      .notesItem {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 30px;

        h5 {
          margin: 0;
          padding: 0;
          font-size: 14px;
          font-weight: 400;
          color: #171717;
          font-family: "Inter";
          margin-bottom: 4px;
          max-width: 350px;

          overflow: hidden;
        }

        span {
          color: #a3a3a3;
          font-weight: 400;
          font-size: 14px;
        }

        .notes_actions {
          display: flex;
          gap: 20px;

          svg {
            cursor: pointer;
          }
        }
      }
    }

    .addNote {
      position: absolute;
      bottom: 16px;
      width: 89%;
      margin-top: 30px;
      display: flex;
      gap: 8px;

      input {
        width: 100%;
        border: 1px solid #e5e5e5;
        padding: 10px 12px;
        border-radius: 4px;
        font-size: 14px;
        font-family: "Inter";
        font-weight: 400;
        color: 171717;

        ::placeholder {
          color: #a3a3a3;
        }
      }

      button {
        width: 44px;
        background-color: #1271a6;
        color: #fff;
        border: none;
        border-radius: 2px;
        padding: 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
    }
  }
}
