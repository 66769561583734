.card {
  width: calc(25% - 14px);
  padding: 20px 30px;
  background: #ffffff;
  border-radius: 4px;
  margin: 7px;
  cursor: pointer;
  transition: box-shadow 0.3s;
  box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.1);

  .menuDots {
    width: 20px;
    height: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: -6px;
  }

  @media (max-width: 1520px) {
    width: calc(33.33% - 14px);
  }

  &:hover {
    box-shadow: rgb(148 182 202) 0px -2px 12px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      color: #171717;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: 200px;
      white-space: normal;
      word-break: break-all;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .progressWrapper {
    margin-bottom: 16px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;

      .title {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #404040;
      }

      .text {
        font-family: "Inter";
        font-size: 14px;
        line-height: 24px;
        color: #737373;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .update {
      color: #737373;
      font-family: "Inter";
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }

    .deadline {
      font-family: "Inter";
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #737373;
      text-transform: capitalize;
    }
  }
}

.menuItem,
.deleteItem {
  display: flex;
  align-items: center;
  gap: 10px;
  color: "#1B2A3D";
  padding: 4px 0px;
}
.menuItem {
  &:hover {
    color: #1271a6;
  }
}
.deleteItem {
  &:hover {
    color: red;
  }
}
